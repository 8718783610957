import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  CalendarDays,
  BarChart2,
  Clock,
  Zap,
  PlusCircle,
  FileText,
  History,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

interface AutomationCardProps {
  title: string;
  status: "active" | "pending" | "failed";
  lastRun: string;
  nextRun: string;
  platform: string;
  type: string;
}

interface DashboardGridProps {
  automations?: AutomationCardProps[];
  totalRuns?: number;
  successRate?: number;
  timesSaved?: number;
}

const defaultAutomations: AutomationCardProps[] = [
  {
    title: "Financial Monthly Export",
    status: "active",
    lastRun: "2024-06-01T10:30:00",
    nextRun: "2024-07-01T10:30:00",
    platform: "Finance Dashboard",
    type: "Export",
  },
  {
    title: "Salesforce Lead Import",
    status: "pending",
    lastRun: "2024-05-15T14:45:00",
    nextRun: "2024-06-15T14:45:00",
    platform: "Salesforce",
    type: "Import",
  },
  {
    title: "HubSpot Contact Sync",
    status: "failed",
    lastRun: "2024-05-28T09:15:00",
    nextRun: "2024-06-04T09:15:00",
    platform: "HubSpot",
    type: "Sync",
  },
];

const AutomationCard = ({
  title,
  status,
  lastRun,
  nextRun,
  platform,
  type,
}: AutomationCardProps) => {
  // Format date to display in a more readable format
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "active":
        return "bg-green-500";
      case "pending":
        return "bg-yellow-500";
      case "failed":
        return "bg-red-500";
      default:
        return "bg-gray-500";
    }
  };

  return (
    <Card className="border border-gray-200 shadow-sm hover:shadow-md transition-shadow">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div className="flex items-center gap-2">
          <span
            className={`h-2 w-2 rounded-full ${getStatusColor(status)}`}
          ></span>
          <CardTitle className="text-sm font-medium">{title}</CardTitle>
        </div>
        <div className="h-4 w-4 text-gray-500">
          <Zap className="h-4 w-4" />
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex justify-between text-sm text-gray-500">
            <div>
              Platform: <span className="font-medium">{platform}</span>
            </div>
            <div>
              Type: <span className="font-medium">{type}</span>
            </div>
          </div>
          <div className="flex justify-between text-sm text-gray-500">
            <div className="flex items-center gap-2">
              <History className="h-4 w-4" />
              <span>Last: {formatDate(lastRun)}</span>
            </div>
            <div className="flex items-center gap-2">
              <Clock className="h-4 w-4" />
              <span>Next: {formatDate(nextRun)}</span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const DashboardGrid = ({
  automations = defaultAutomations,
  totalRuns = 42,
  successRate = 95,
  timesSaved = 63,
}: DashboardGridProps) => {
  return (
    <div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {/* Summary Cards */}
        <Card className="border border-gray-200 shadow-sm">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Total Automations
            </CardTitle>
            <Zap className="h-4 w-4 text-blue-500" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{automations.length}</div>
            <p className="text-xs text-gray-500">Active automations</p>
          </CardContent>
        </Card>
        <Card className="border border-gray-200 shadow-sm">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Success Rate</CardTitle>
            <BarChart2 className="h-4 w-4 text-green-500" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{successRate}%</div>
            <Progress value={successRate} className="h-2 mt-2" />
            <p className="text-xs text-gray-500 mt-2">
              Based on {totalRuns} runs
            </p>
          </CardContent>
        </Card>
        <Card className="border border-gray-200 shadow-sm">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Time Saved</CardTitle>
            <CalendarDays className="h-4 w-4 text-blue-500" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{timesSaved} hrs</div>
            <p className="text-xs text-gray-500">This month</p>
          </CardContent>
        </Card>
      </div>

      {/* Automation Cards */}
      <div className="mt-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium">Your Automations</h3>
          <Link to="/dashboard/automations">
            <Button variant="ghost" size="sm" className="text-sm">
              View All
            </Button>
          </Link>
        </div>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {automations.map((automation, index) => (
            <AutomationCard key={index} {...automation} />
          ))}
        </div>
      </div>

      {/* Quick Actions */}
      <div className="mt-8">
        <h3 className="text-lg font-medium mb-4">Quick Actions</h3>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          <Link to="/dashboard/automations/create">
            <Button
              variant="outline"
              className="w-full justify-start gap-2 h-12 border-blue-200 hover:border-blue-300 hover:bg-blue-50"
            >
              <PlusCircle className="h-5 w-5 text-blue-600" />
              <div className="text-left">
                <div className="font-medium">Create Automation</div>
                <div className="text-xs text-gray-600">
                  Set up a new automated task
                </div>
              </div>
            </Button>
          </Link>
          <Link to="/dashboard/automations">
            <Button
              variant="outline"
              className="w-full justify-start gap-2 h-12"
            >
              <Zap className="h-5 w-5 text-primary" />
              <div className="text-left">
                <div className="font-medium">Manage Automations</div>
                <div className="text-xs text-muted-foreground">
                  View and edit your automations
                </div>
              </div>
            </Button>
          </Link>
          <Link to="/dashboard/analytics">
            <Button
              variant="outline"
              className="w-full justify-start gap-2 h-12"
            >
              <BarChart2 className="h-5 w-5 text-primary" />
              <div className="text-left">
                <div className="font-medium">Analytics</div>
                <div className="text-xs text-muted-foreground">
                  View performance metrics
                </div>
              </div>
            </Button>
          </Link>
        </div>
      </div>

      {/* Recent Activity */}
      <div className="mt-8">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium">Recent Activity</h3>
          <Button variant="ghost" size="sm" className="text-sm">
            View All
          </Button>
        </div>
        <Card className="border border-gray-200 shadow-sm">
          <CardContent className="p-4">
            <div className="space-y-4">
              {[1, 2, 3].map((_, i) => (
                <div
                  key={i}
                  className="flex items-start gap-3 pb-4 border-b border-gray-100 last:border-0 last:pb-0"
                >
                  <Avatar className="h-8 w-8">
                    <AvatarImage
                      src={`https://api.dicebear.com/7.x/avataaars/svg?seed=Activity${i}`}
                    />
                    <AvatarFallback>A</AvatarFallback>
                  </Avatar>
                  <div>
                    <p className="text-sm">
                      <span className="font-medium">
                        Financial Monthly Export
                      </span>{" "}
                      completed successfully
                    </p>
                    <p className="text-xs text-gray-500 mt-1">2 hours ago</p>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DashboardGrid;
