import React from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { PlusCircle, Zap, Clock, CheckCircle, AlertCircle } from "lucide-react";
import { motion } from "framer-motion";
import { Badge } from "@/components/ui/badge";

interface Automation {
  id: string;
  title: string;
  description: string;
  status: "active" | "pending" | "completed" | "failed";
  platform: string;
  lastRun?: string;
  nextRun?: string;
}

interface AutomationBoardProps {
  automations?: Automation[];
  onAutomationMove?: (
    automationId: string,
    newStatus: Automation["status"],
  ) => void;
  onAutomationClick?: (automation: Automation) => void;
}

const defaultAutomations: Automation[] = [
  {
    id: "1",
    title: "QuickBooks Monthly Export",
    description: "Export financial data from QuickBooks",
    status: "active",
    platform: "QuickBooks",
    lastRun: "2024-06-01",
    nextRun: "2024-07-01",
  },
  {
    id: "2",
    title: "Salesforce Lead Import",
    description: "Import new leads from CSV to Salesforce",
    status: "pending",
    platform: "Salesforce",
    nextRun: "2024-06-15",
  },
  {
    id: "3",
    title: "HubSpot Contact Sync",
    description: "Sync contacts between systems",
    status: "completed",
    platform: "HubSpot",
    lastRun: "2024-05-28",
  },
  {
    id: "4",
    title: "Google Sheets Data Export",
    description: "Export analytics data to Google Sheets",
    status: "failed",
    platform: "Google Sheets",
    lastRun: "2024-05-30",
  },
];

const getStatusIcon = (status: Automation["status"]) => {
  switch (status) {
    case "active":
      return <Zap className="h-4 w-4 text-blue-500" />;
    case "pending":
      return <Clock className="h-4 w-4 text-yellow-500" />;
    case "completed":
      return <CheckCircle className="h-4 w-4 text-green-500" />;
    case "failed":
      return <AlertCircle className="h-4 w-4 text-red-500" />;
    default:
      return <Zap className="h-4 w-4 text-gray-500" />;
  }
};

const AutomationBoard = ({
  automations = defaultAutomations,
  onAutomationMove = () => {},
  onAutomationClick = () => {},
}: AutomationBoardProps) => {
  const columns = [
    { id: "active", title: "Active", color: "bg-blue-50 border-blue-100" },
    {
      id: "pending",
      title: "Pending",
      color: "bg-yellow-50 border-yellow-100",
    },
    {
      id: "completed",
      title: "Completed",
      color: "bg-green-50 border-green-100",
    },
    { id: "failed", title: "Failed", color: "bg-red-50 border-red-100" },
  ];

  const handleDragStart = (e: React.DragEvent, automationId: string) => {
    e.dataTransfer.setData("automationId", automationId);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent, status: Automation["status"]) => {
    e.preventDefault();
    const automationId = e.dataTransfer.getData("automationId");
    onAutomationMove(automationId, status);
  };

  // Format date to display in a more readable format
  const formatDate = (dateString?: string) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
    }).format(date);
  };

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-800">
          Automation Board
        </h2>
        <Button variant="outline" size="sm" className="gap-1 text-sm">
          <PlusCircle className="h-4 w-4" />
          New Automation
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {columns.map((column) => (
          <div
            key={column.id}
            className={`${column.color} rounded-lg p-4 border shadow-sm`}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, column.id as Automation["status"])}
          >
            <h3 className="font-medium text-gray-700 mb-4 flex items-center gap-2">
              {getStatusIcon(column.id as Automation["status"])}
              {column.title}
              <span className="ml-1 text-xs bg-white px-2 py-0.5 rounded-full">
                {automations.filter((a) => a.status === column.id).length}
              </span>
            </h3>
            <div className="space-y-3">
              {automations
                .filter((automation) => automation.status === column.id)
                .map((automation) => (
                  <motion.div
                    key={automation.id}
                    layoutId={automation.id}
                    draggable
                    onDragStart={(e) =>
                      handleDragStart(e as any, automation.id)
                    }
                    onClick={() => onAutomationClick(automation)}
                  >
                    <Card className="p-3 cursor-pointer hover:shadow-md transition-shadow border border-gray-200 bg-white">
                      <h4 className="font-medium text-gray-800 mb-2 flex items-center justify-between">
                        <span>{automation.title}</span>
                        {getStatusIcon(automation.status)}
                      </h4>
                      <p className="text-sm text-gray-600 mb-3">
                        {automation.description}
                      </p>
                      <div className="flex flex-wrap gap-2">
                        <Badge variant="outline" className="text-xs bg-gray-50">
                          {automation.platform}
                        </Badge>
                        {automation.lastRun && (
                          <Badge
                            variant="outline"
                            className="text-xs bg-gray-50"
                          >
                            Last: {formatDate(automation.lastRun)}
                          </Badge>
                        )}
                        {automation.nextRun && (
                          <Badge
                            variant="outline"
                            className="text-xs bg-gray-50"
                          >
                            Next: {formatDate(automation.nextRun)}
                          </Badge>
                        )}
                      </div>
                    </Card>
                  </motion.div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutomationBoard;
