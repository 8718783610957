import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { ArrowLeft, ArrowRight, Plus, Save, Trash } from "lucide-react";

const platforms = [
  { id: "finance", name: "Finance Dashboard" },
  { id: "salesforce", name: "Salesforce" },
  { id: "hubspot", name: "HubSpot" },
  { id: "excel", name: "Excel" },
  { id: "google_sheets", name: "Google Sheets" },
  { id: "linkedin", name: "LinkedIn" },
];

interface Step {
  id: string;
  type: string;
  description: string;
  config: Record<string, any>;
}

interface AutomationBuilderProps {
  initialSteps?: Step[];
  onStepsChange?: (steps: Step[]) => void;
  platform?: string;
  onNext?: () => void;
  onBack?: () => void;
}

const AutomationBuilder = ({
  initialSteps = [],
  onStepsChange = () => {},
  platform = "",
  onNext = () => {},
  onBack = () => {},
}: AutomationBuilderProps) => {
  const [steps, setSteps] = useState<Step[]>(
    initialSteps.length > 0
      ? initialSteps
      : [
          {
            id: "1",
            type: "connect",
            description: `Connect to ${getPlatformName(platform) || "platform"}`,
            config: {},
          },
        ],
  );

  useEffect(() => {
    if (initialSteps.length > 0) {
      setSteps(initialSteps);
    } else if (platform && steps.length === 1 && steps[0].type === "connect") {
      // Update the first step description if platform changes
      setSteps([
        {
          ...steps[0],
          description: `Connect to ${getPlatformName(platform) || "platform"}`,
        },
      ]);
    }
  }, [platform, initialSteps]);

  useEffect(() => {
    onStepsChange(steps);
  }, [steps, onStepsChange]);

  function getPlatformName(platformId: string): string {
    const platform = platforms.find((p) => p.id === platformId);
    return platform ? platform.name : platformId;
  }

  const addStep = () => {
    const newStep: Step = {
      id: `step-${Date.now()}`,
      type: "action",
      description: "New step",
      config: {},
    };
    setSteps([...steps, newStep]);
  };

  const removeStep = (id: string) => {
    setSteps(steps.filter((step) => step.id !== id));
  };

  const updateStep = (id: string, field: string, value: any) => {
    setSteps(
      steps.map((step) =>
        step.id === id ? { ...step, [field]: value } : step,
      ),
    );
  };

  const updateStepConfig = (id: string, field: string, value: any) => {
    setSteps(
      steps.map((step) =>
        step.id === id
          ? {
              ...step,
              config: { ...step.config, [field]: value },
            }
          : step,
      ),
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Configure Automation Steps</CardTitle>
        <CardDescription>
          Define the sequence of actions for your automation
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium">Automation Steps</h3>
            <Button variant="outline" size="sm" onClick={addStep}>
              <Plus className="h-4 w-4 mr-2" /> Add Step
            </Button>
          </div>

          <div className="space-y-4">
            {steps.map((step, index) => (
              <div
                key={step.id}
                className="border rounded-md p-4 space-y-4 relative"
              >
                <div className="absolute top-4 right-4">
                  {index > 0 && (
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => removeStep(step.id)}
                    >
                      <Trash className="h-4 w-4 text-red-500" />
                    </Button>
                  )}
                </div>

                <div className="flex items-center">
                  <div className="w-8 h-8 rounded-full bg-primary text-white flex items-center justify-center mr-3">
                    {index + 1}
                  </div>
                  <div className="font-medium">{step.description}</div>
                </div>

                <div className="space-y-4 pl-11">
                  <div className="space-y-2">
                    <Label htmlFor={`step-${step.id}-type`}>Step Type</Label>
                    <Select
                      value={step.type}
                      onValueChange={(value) =>
                        updateStep(step.id, "type", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="connect">Connect</SelectItem>
                        <SelectItem value="navigate">Navigate</SelectItem>
                        <SelectItem value="click">Click</SelectItem>
                        <SelectItem value="input">Input Data</SelectItem>
                        <SelectItem value="extract">Extract Data</SelectItem>
                        <SelectItem value="wait">Wait</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor={`step-${step.id}-description`}>
                      Description
                    </Label>
                    <Input
                      id={`step-${step.id}-description`}
                      value={step.description}
                      onChange={(e) =>
                        updateStep(step.id, "description", e.target.value)
                      }
                      placeholder="What does this step do?"
                    />
                  </div>

                  {step.type === "navigate" && (
                    <div className="space-y-2">
                      <Label htmlFor={`step-${step.id}-url`}>URL or Path</Label>
                      <Input
                        id={`step-${step.id}-url`}
                        placeholder="e.g., /reports or https://example.com/reports"
                        value={step.config.url || ""}
                        onChange={(e) =>
                          updateStepConfig(step.id, "url", e.target.value)
                        }
                      />
                    </div>
                  )}

                  {step.type === "click" && (
                    <div className="space-y-2">
                      <Label htmlFor={`step-${step.id}-selector`}>
                        Button Selector
                      </Label>
                      <Input
                        id={`step-${step.id}-selector`}
                        placeholder="e.g., #export-button"
                        value={step.config.selector || ""}
                        onChange={(e) =>
                          updateStepConfig(step.id, "selector", e.target.value)
                        }
                      />
                    </div>
                  )}

                  {step.type === "input" && (
                    <div className="space-y-2">
                      <Label htmlFor={`step-${step.id}-field`}>
                        Input Field
                      </Label>
                      <Input
                        id={`step-${step.id}-field`}
                        placeholder="e.g., #search-input"
                        value={step.config.field || ""}
                        onChange={(e) =>
                          updateStepConfig(step.id, "field", e.target.value)
                        }
                      />
                      <Label htmlFor={`step-${step.id}-value`}>
                        Value to Enter
                      </Label>
                      <Input
                        id={`step-${step.id}-value`}
                        placeholder="e.g., Monthly Report"
                        value={step.config.value || ""}
                        onChange={(e) =>
                          updateStepConfig(step.id, "value", e.target.value)
                        }
                      />
                    </div>
                  )}

                  {step.type === "extract" && (
                    <div className="space-y-2">
                      <Label htmlFor={`step-${step.id}-selector`}>
                        Element Selector
                      </Label>
                      <Input
                        id={`step-${step.id}-selector`}
                        placeholder="e.g., .report-data"
                        value={step.config.selector || ""}
                        onChange={(e) =>
                          updateStepConfig(step.id, "selector", e.target.value)
                        }
                      />
                      <Label htmlFor={`step-${step.id}-dataType`}>
                        Data Type
                      </Label>
                      <Select
                        value={step.config.dataType || "text"}
                        onValueChange={(value) =>
                          updateStepConfig(step.id, "dataType", value)
                        }
                      >
                        <SelectTrigger id={`step-${step.id}-dataType`}>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="text">Text</SelectItem>
                          <SelectItem value="html">HTML</SelectItem>
                          <SelectItem value="attribute">Attribute</SelectItem>
                          <SelectItem value="table">Table</SelectItem>
                        </SelectContent>
                      </Select>
                      {step.config.dataType === "attribute" && (
                        <div className="mt-2">
                          <Label htmlFor={`step-${step.id}-attribute`}>
                            Attribute Name
                          </Label>
                          <Input
                            id={`step-${step.id}-attribute`}
                            placeholder="e.g., href, data-id"
                            value={step.config.attribute || ""}
                            onChange={(e) =>
                              updateStepConfig(
                                step.id,
                                "attribute",
                                e.target.value,
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {step.type === "wait" && (
                    <div className="space-y-2">
                      <Label htmlFor={`step-${step.id}-duration`}>
                        Duration (seconds)
                      </Label>
                      <Input
                        id={`step-${step.id}-duration`}
                        type="number"
                        min="1"
                        value={step.config.duration || "5"}
                        onChange={(e) =>
                          updateStepConfig(step.id, "duration", e.target.value)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button variant="outline" onClick={onBack}>
          <ArrowLeft className="mr-2 h-4 w-4" /> Back
        </Button>
        <Button onClick={onNext}>
          Next <ArrowRight className="ml-2 h-4 w-4" />
        </Button>
      </CardFooter>
    </Card>
  );
};

export default AutomationBuilder;
