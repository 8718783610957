import React, { useState } from "react";
import {
  Bell,
  Home,
  Search,
  Settings,
  User,
  Menu,
  Zap,
  HelpCircle,
  Plus,
  LogOut,
  CreditCard,
  UserCog,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useAuth } from "../../../../supabase/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Badge } from "@/components/ui/badge";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

interface Notification {
  id: string;
  title: string;
  description?: string;
  time: string;
  read: boolean;
  type: "success" | "error" | "info" | "warning";
}

interface TopNavigationProps {
  onSearch?: (query: string) => void;
  onMenuClick?: () => void;
  notifications?: Notification[];
}

const defaultNotifications: Notification[] = [
  {
    id: "1",
    title: "Automation completed",
    description: "QuickBooks Monthly Export completed successfully",
    time: "5 minutes ago",
    read: false,
    type: "success",
  },
  {
    id: "2",
    title: "Export ready",
    description: "Your QuickBooks data export is ready for download",
    time: "10 minutes ago",
    read: false,
    type: "info",
  },
  {
    id: "3",
    title: "Automation failed",
    description: "Salesforce Lead Import failed - check logs",
    time: "1 hour ago",
    read: false,
    type: "error",
  },
];

const TopNavigation = ({
  onSearch = () => {},
  onMenuClick = () => {},
  notifications = defaultNotifications,
}: TopNavigationProps) => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const unreadCount = notifications.filter((n) => !n.read).length;

  if (!user) return null;

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch(searchQuery);
  };

  // Generate breadcrumbs based on current path
  const generateBreadcrumbs = () => {
    const paths = location.pathname.split("/").filter(Boolean);

    // Skip generating breadcrumbs if we're at the root dashboard
    if (paths.length <= 1) return null;

    const breadcrumbs = paths.map((path, index) => {
      // Build the URL for this breadcrumb
      const url = `/${paths.slice(0, index + 1).join("/")}`;

      // Format the label (capitalize, replace hyphens with spaces)
      const label =
        path.charAt(0).toUpperCase() + path.slice(1).replace(/-/g, " ");

      return { label, url };
    });

    return breadcrumbs;
  };

  const breadcrumbs = generateBreadcrumbs();

  const getNotificationIcon = (type: Notification["type"]) => {
    switch (type) {
      case "success":
        return <Badge className="h-2 w-2 rounded-full bg-green-500" />;
      case "error":
        return <Badge className="h-2 w-2 rounded-full bg-red-500" />;
      case "warning":
        return <Badge className="h-2 w-2 rounded-full bg-yellow-500" />;
      default:
        return <Badge className="h-2 w-2 rounded-full bg-blue-500" />;
    }
  };

  return (
    <div className="w-full h-16 border-b border-gray-200 bg-white flex flex-col fixed top-16 z-40 shadow-sm">
      {/* Main navigation bar */}
      <div className="w-full h-16 flex items-center justify-between px-4">
        <div className="flex items-center gap-3">
          <Button
            variant="ghost"
            size="icon"
            onClick={onMenuClick}
            className="md:hidden"
          >
            <Menu className="h-5 w-5 text-gray-700" />
          </Button>

          <Link to="/dashboard" className="flex items-center gap-2">
            <div className="bg-black text-white p-1 rounded-md">
              <Zap className="h-5 w-5" />
            </div>
            <span className="font-semibold text-lg hidden md:inline-block">
              1Button.io
            </span>
          </Link>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="ml-1 text-gray-700"
                  onClick={() => navigate("/dashboard")}
                >
                  <Home className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Dashboard Home</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          {breadcrumbs && (
            <Breadcrumb className="hidden md:flex ml-2">
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="/dashboard">
                    Dashboard
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />

                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={crumb.url}>
                    <BreadcrumbItem>
                      {index === breadcrumbs.length - 1 ? (
                        <span className="font-medium">{crumb.label}</span>
                      ) : (
                        <BreadcrumbLink as={Link} to={crumb.url}>
                          {crumb.label}
                        </BreadcrumbLink>
                      )}
                    </BreadcrumbItem>
                    {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
                  </React.Fragment>
                ))}
              </BreadcrumbList>
            </Breadcrumb>
          )}
        </div>

        <div className="flex-1 mx-4 hidden md:block">
          <form
            onSubmit={handleSearch}
            className="relative w-full max-w-xs mx-auto"
          >
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
            <Input
              placeholder="Search automations..."
              className="pl-8 h-9 text-sm border-gray-200 focus:border-gray-300"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </form>
        </div>

        <div className="flex items-center gap-1 md:gap-3 ml-auto">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  className="hidden md:flex items-center gap-1"
                  onClick={() => navigate("/dashboard/automations/create")}
                >
                  <Plus className="h-4 w-4" />
                  <span>New Automation</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Create a new automation</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="relative text-gray-700"
                    >
                      <Bell className="h-5 w-5" />
                      {unreadCount > 0 && (
                        <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                          {unreadCount}
                        </span>
                      )}
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-80">
                    <DropdownMenuLabel className="flex justify-between items-center">
                      <span>Notifications</span>
                      {unreadCount > 0 && (
                        <Badge variant="secondary" className="ml-2">
                          {unreadCount} new
                        </Badge>
                      )}
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {notifications.length === 0 ? (
                      <div className="py-4 text-center text-sm text-gray-500">
                        No notifications
                      </div>
                    ) : (
                      notifications.map((notification) => (
                        <DropdownMenuItem
                          key={notification.id}
                          className="py-3 px-4 cursor-pointer"
                        >
                          <div className="flex gap-3 w-full">
                            <div className="mt-0.5">
                              {getNotificationIcon(notification.type)}
                            </div>
                            <div className="flex-1">
                              <div className="flex justify-between items-start">
                                <p
                                  className={`font-medium text-sm ${!notification.read ? "text-primary" : ""}`}
                                >
                                  {notification.title}
                                </p>
                                <span className="text-xs text-gray-500 ml-2">
                                  {notification.time}
                                </span>
                              </div>
                              {notification.description && (
                                <p className="text-xs text-gray-600 mt-1">
                                  {notification.description}
                                </p>
                              )}
                            </div>
                          </div>
                        </DropdownMenuItem>
                      ))
                    )}
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      className="py-2 justify-center"
                      onSelect={(e) => {
                        e.preventDefault();
                        navigate("/dashboard/notifications");
                      }}
                    >
                      <span className="text-primary text-sm font-medium">
                        View all notifications
                      </span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TooltipTrigger>
              <TooltipContent>
                <p>Notifications</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="text-gray-700 hidden md:flex"
                >
                  <HelpCircle className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Help & Support</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="gap-2 text-gray-700">
                <Avatar className="h-8 w-8">
                  <AvatarImage
                    src={`https://api.dicebear.com/7.x/avataaars/svg?seed=${user.email}`}
                    alt={user.email || ""}
                  />
                  <AvatarFallback>
                    {user.email?.[0].toUpperCase()}
                  </AvatarFallback>
                </Avatar>
                <span className="hidden md:inline-block text-sm max-w-[100px] truncate">
                  {user.email}
                </span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-56">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="py-2"
                onClick={() => navigate("/dashboard/profile")}
              >
                <User className="mr-2 h-4 w-4" />
                Profile
              </DropdownMenuItem>
              <DropdownMenuItem
                className="py-2"
                onClick={() => navigate("/dashboard/settings")}
              >
                <Settings className="mr-2 h-4 w-4" />
                Settings
              </DropdownMenuItem>
              <DropdownMenuItem
                className="py-2"
                onClick={() => navigate("/dashboard/account")}
              >
                <UserCog className="mr-2 h-4 w-4" />
                Account
              </DropdownMenuItem>
              <DropdownMenuItem
                className="py-2"
                onClick={() => navigate("/dashboard/billing")}
              >
                <CreditCard className="mr-2 h-4 w-4" />
                Billing
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onSelect={() => signOut()}
                className="py-2 text-red-600"
              >
                <LogOut className="mr-2 h-4 w-4" />
                Log out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};

export default TopNavigation;
