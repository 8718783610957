import React from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Separator } from "@/components/ui/separator";
import { Badge } from "@/components/ui/badge";
import { Zap, AlertCircle, CheckCircle, Clock, FileText } from "lucide-react";
import { Card } from "@/components/ui/card";

interface ActivityItem {
  id: string;
  type: "success" | "error" | "pending" | "info";
  automation: {
    name: string;
    platform: string;
  };
  content: string;
  timestamp: string;
  details?: string;
}

interface ActivityFeedProps {
  activities?: ActivityItem[];
  title?: string;
  maxHeight?: string;
}

const defaultActivities: ActivityItem[] = [
  {
    id: "1",
    type: "success",
    automation: {
      name: "Financial Monthly Export",
      platform: "Finance Dashboard",
    },
    content: "Successfully exported financial data",
    timestamp: "5m ago",
    details: "Exported 245 records",
  },
  {
    id: "2",
    type: "error",
    automation: {
      name: "Salesforce Lead Import",
      platform: "Salesforce",
    },
    content: "Failed to import leads",
    timestamp: "15m ago",
    details: "Authentication error",
  },
  {
    id: "3",
    type: "pending",
    automation: {
      name: "HubSpot Contact Sync",
      platform: "HubSpot",
    },
    content: "Scheduled to run in 2 hours",
    timestamp: "1h ago",
  },
  {
    id: "4",
    type: "info",
    automation: {
      name: "Google Sheets Data Export",
      platform: "Google Sheets",
    },
    content: "Automation created and configured",
    timestamp: "2h ago",
    details: "First run scheduled for tomorrow",
  },
];

const getActivityIcon = (type: ActivityItem["type"]) => {
  switch (type) {
    case "success":
      return <CheckCircle className="h-4 w-4 text-green-500" />;
    case "error":
      return <AlertCircle className="h-4 w-4 text-red-500" />;
    case "pending":
      return <Clock className="h-4 w-4 text-yellow-500" />;
    case "info":
      return <FileText className="h-4 w-4 text-blue-500" />;
    default:
      return <Zap className="h-4 w-4 text-gray-500" />;
  }
};

const getActivityColor = (type: ActivityItem["type"]) => {
  switch (type) {
    case "success":
      return "bg-green-50 border-green-100";
    case "error":
      return "bg-red-50 border-red-100";
    case "pending":
      return "bg-yellow-50 border-yellow-100";
    case "info":
      return "bg-blue-50 border-blue-100";
    default:
      return "bg-gray-50 border-gray-100";
  }
};

const ActivityFeed = ({
  activities = defaultActivities,
  title = "Activity Feed",
  maxHeight = "calc(100vh-180px)",
}: ActivityFeedProps) => {
  return (
    <Card className="border border-gray-200 shadow-sm h-full">
      <div className="p-4 h-full flex flex-col">
        <h2 className="text-lg font-semibold mb-4">{title}</h2>
        <ScrollArea className={`h-[${maxHeight}] flex-1`}>
          <div className="space-y-3 pr-4">
            {activities.map((activity, index) => (
              <div
                key={activity.id}
                className={`group rounded-lg border p-3 ${getActivityColor(activity.type)}`}
              >
                <div className="flex items-start gap-3">
                  <div className="mt-0.5">{getActivityIcon(activity.type)}</div>
                  <div className="flex-1 space-y-1">
                    <div className="flex items-center justify-between">
                      <span className="font-medium text-sm">
                        {activity.automation.name}
                      </span>
                      <span className="text-xs text-gray-500">
                        {activity.timestamp}
                      </span>
                    </div>
                    <p className="text-sm text-gray-600">{activity.content}</p>
                    <div className="flex flex-wrap gap-2 mt-2">
                      <Badge variant="outline" className="text-xs bg-white">
                        {activity.automation.platform}
                      </Badge>
                      {activity.details && (
                        <Badge variant="outline" className="text-xs bg-white">
                          {activity.details}
                        </Badge>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
      </div>
    </Card>
  );
};

export default ActivityFeed;
