import { ReactNode } from "react";
import Header from "../layout/Header";

export default function AuthLayout({ children }: { children: ReactNode }) {
  return (
    <>
      <Header />
      <div className="min-h-screen flex items-center justify-center bg-slate-50 pt-16">
        <div className="max-w-md w-full px-4">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold">1Button.io</h1>
            <p className="text-slate-600 mt-2">
              Automate enterprise software with one button
            </p>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}
