import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useToast } from "@/components/ui/use-toast";
import { AlertCircle, Check, RefreshCw } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../../supabase/supabase";
import { useAuth } from "../../../supabase/auth";

export default function ResetDatabase() {
  const [isResetting, setIsResetting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleReset = async () => {
    if (
      !confirm(
        "WARNING: This will delete ALL users, sessions, and tokens from the database. This action cannot be undone. Are you sure you want to continue?",
      )
    ) {
      return;
    }

    setIsResetting(true);
    setError("");
    setSuccess(false);

    try {
      const { data, error } = await supabase.functions.invoke("reset-database");

      if (error) {
        throw error;
      }

      setSuccess(true);
      toast({
        title: "Database Reset Successful",
        description: "All users, sessions, and tokens have been removed.",
      });

      // Sign out the current user since their session is now invalid
      setTimeout(async () => {
        await signOut();
        navigate("/login");
      }, 3000);
    } catch (err) {
      console.error("Error resetting database:", err);
      setError(
        err.message || "An error occurred while resetting the database.",
      );
      toast({
        title: "Reset Failed",
        description: "There was a problem resetting the database.",
        variant: "destructive",
      });
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <div className="container mx-auto py-10 max-w-md">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl flex items-center gap-2">
            <RefreshCw className="h-6 w-6 text-red-500" /> Database Reset
          </CardTitle>
          <CardDescription>
            Reset the database by removing all users, sessions, and tokens.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {error && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {success && (
            <Alert className="bg-green-50 border-green-200">
              <Check className="h-4 w-4 text-green-600" />
              <AlertTitle className="text-green-800">Success</AlertTitle>
              <AlertDescription className="text-green-700">
                Database reset successful. You will be redirected to the login
                page.
              </AlertDescription>
            </Alert>
          )}

          <div className="bg-amber-50 border border-amber-200 rounded-md p-4 text-amber-800">
            <h3 className="font-bold flex items-center gap-2">
              <AlertCircle className="h-4 w-4 text-amber-600" /> Warning
            </h3>
            <p className="text-sm mt-2">
              This action will permanently delete all users, sessions, and
              tokens from the database. This is intended for development and
              testing purposes only.
            </p>
          </div>
        </CardContent>
        <CardFooter>
          <Button
            variant="destructive"
            className="w-full"
            onClick={handleReset}
            disabled={isResetting || success}
          >
            {isResetting ? (
              <>
                <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></span>
                Resetting Database...
              </>
            ) : (
              "Reset Database"
            )}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
