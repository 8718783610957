import { supabase } from "./supabase";
import {
  ensureUserExists,
  getUserProfile,
  updateUserProfile,
  deleteUser,
} from "./user-management";
import type { UserProfile } from "./user-management";

/**
 * Sign up a new user with email and password
 */
export async function signUp(
  email: string,
  password: string,
  fullName: string,
) {
  try {
    console.log("Starting signup process for:", email);

    // Create the user in auth.users
    const { data: authData, error: authError } = await supabase.auth.signUp({
      email: email.trim(),
      password,
      options: {
        data: { full_name: fullName.trim() },
        emailRedirectTo: `${window.location.origin}/auth-callback`,
      },
    });

    if (authError) {
      console.error("Auth error during signup:", authError);
      throw authError;
    }

    if (!authData.user) {
      console.error("No user returned from auth signup");
      throw new Error("Failed to create user account");
    }

    console.log(
      "Auth signup successful, user created with ID:",
      authData.user.id,
    );

    // Ensure the user exists in the public.users table
    const userProfile = await ensureUserExists(authData.user);

    if (!userProfile) {
      console.warn("User created in auth but profile creation failed");
    }

    return authData.user;
  } catch (error) {
    console.error("Error in signUp function:", error);
    throw error;
  }
}

/**
 * Sign in a user with email and password
 */
export async function signIn(email: string, password: string) {
  try {
    console.log("Attempting to sign in user:", email);

    // Trim the email to remove any accidental whitespace
    const trimmedEmail = email.trim();

    const { data, error } = await supabase.auth.signInWithPassword({
      email: trimmedEmail,
      password,
    });

    if (error) {
      console.error("Sign in error from Supabase:", error);
      throw error;
    }

    if (!data?.user) {
      console.error("No user returned from sign in");
      throw new Error("Sign in failed - no user returned");
    }

    console.log("Sign in successful for user ID:", data.user.id);

    // Ensure user exists in the database after successful sign-in
    await ensureUserExists(data.user);

    return data;
  } catch (error) {
    console.error("Sign in error:", error);
    throw error;
  }
}

/**
 * Sign in with Google OAuth
 */
export async function signInWithGoogle() {
  try {
    // Get the current origin (protocol + hostname + port)
    const origin = window.location.origin;
    // Create the redirect URL using the origin to ensure it's not localhost
    const redirectUrl = `${origin}/auth-callback`;

    console.log("Google auth redirecting to:", redirectUrl);

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: redirectUrl,
        scopes: "email profile",
        queryParams: {
          access_type: "offline",
          prompt: "consent",
        },
        skipBrowserRedirect: false, // Ensure browser redirects to Google auth
      },
    });

    // Log the OAuth URL for debugging
    if (data?.url) {
      console.log("OAuth URL generated:", data.url);
    }

    if (error) {
      console.error("OAuth error:", error);
      throw error;
    }

    // The user will be redirected to Google for authentication
    // When they return, the onAuthStateChange handler will process the session
    return data;
  } catch (error) {
    console.error("Error in signInWithGoogle:", error);
    throw error;
  }
}

/**
 * Sign out the current user
 */
export async function signOut() {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    return true;
  } catch (error) {
    console.error("Sign out error:", error);
    throw error;
  }
}

/**
 * Send a password reset email
 */
export async function resetPassword(email: string) {
  try {
    // Get the current origin (protocol + hostname + port)
    const origin = window.location.origin;
    // Create the reset URL using the origin
    const resetUrl = `${origin}/reset-password`;

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: resetUrl,
    });

    if (error) throw error;
    return true;
  } catch (error) {
    console.error("Reset password error:", error);
    throw error;
  }
}

/**
 * Update a user's password
 */
export async function updatePassword(token: string, newPassword: string) {
  try {
    // The token is automatically picked up from the URL by Supabase
    // when using the hash fragment approach
    const { error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) throw error;
    return true;
  } catch (error) {
    console.error("Update password error:", error);
    throw error;
  }
}

// Export the user management functions
export { getUserProfile, updateUserProfile, deleteUser };
export type { UserProfile };
