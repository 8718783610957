import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Settings,
  User,
  Zap,
  Shield,
  Database,
  Code,
  CheckCircle2,
  ArrowRight,
  Star,
  ChevronRight,
  Github,
  Loader2,
  Twitter,
  Instagram,
  X,
  MousePointer,
  Clock,
  FileText,
  BarChart,
  Calendar,
  Repeat,
  Sparkles,
  Lightbulb,
  Rocket,
  Bot,
  Mail,
} from "lucide-react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../supabase/auth";
import { useEffect, useState } from "react";
import { supabase } from "../../../supabase/supabase";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { Toaster } from "@/components/ui/toaster";
import { ThemeToggle } from "@/components/ui/theme-toggle";
import { useTheme } from "@/components/theme-provider";

// Define the Plan type
interface Plan {
  id: string;
  object: string;
  active: boolean;
  amount: number;
  currency: string;
  interval: string;
  interval_count: number;
  product: string;
  created: number;
  livemode: boolean;
  nickname?: string;
  [key: string]: any;
}

// Testimonial interface
interface Testimonial {
  id: number;
  name: string;
  role: string;
  company: string;
  content: string;
  avatar: string;
}

// Feature interface
interface Feature {
  title: string;
  description: string;
  icon: JSX.Element;
}

// Automation interface
interface Automation {
  title: string;
  description: string;
  icon: JSX.Element;
}

// Benefit interface
interface Benefit {
  title: string;
  description: string;
  icon: JSX.Element;
}

export default function LandingPage() {
  const { theme } = useTheme();
  const { user, signOut } = useAuth();
  const { toast } = useToast();

  const [plans, setPlans] = useState<Plan[]>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [processingPlanId, setProcessingPlanId] = useState<string | null>(null);

  useEffect(() => {
    fetchPlans();
  }, []);

  // We're using hardcoded plans instead of fetching from the API
  // This is a temporary solution until the Edge Function is fixed
  const fetchPlans = async () => {
    try {
      // Hardcoded plans that match what would come from Stripe
      const hardcodedPlans = [
        {
          id: "price_productive",
          nickname: "Productive",
          unit_amount: 2900,
          currency: "usd",
          interval: "month",
          active: true,
        },
        {
          id: "price_workaholic",
          nickname: "Workaholic",
          unit_amount: 7900,
          currency: "usd",
          interval: "month",
          active: true,
        },
      ];

      setPlans(hardcodedPlans);
      setError("");
    } catch (error) {
      console.error("Failed to set plans:", error);
      setError("Failed to load plans. Please try again later.");
    }
  };

  // Handle checkout process
  const handleCheckout = async (priceId: string) => {
    if (!user) {
      // Redirect to login if user is not authenticated
      toast({
        title: "Authentication required",
        description: "Please sign in to subscribe to a plan.",
        variant: "default",
      });
      window.location.href = "/login?redirect=pricing";
      return;
    }

    setIsLoading(true);
    setProcessingPlanId(priceId);
    setError("");

    try {
      const { data, error } = await supabase.functions.invoke(
        "supabase-functions-create-checkout",
        {
          body: {
            price_id: priceId,
            user_id: user.id,
            return_url: `${window.location.origin}/dashboard`,
          },
          headers: {
            "X-Customer-Email": user.email || "",
          },
        },
      );

      if (error) {
        throw error;
      }

      // Redirect to Stripe checkout
      if (data?.url) {
        toast({
          title: "Redirecting to checkout",
          description:
            "You'll be redirected to Stripe to complete your purchase.",
          variant: "default",
        });
        window.location.href = data.url;
      } else {
        throw new Error("No checkout URL returned");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setError("Failed to create checkout session. Please try again.");
      toast({
        title: "Checkout failed",
        description:
          "There was an error creating your checkout session. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
      setProcessingPlanId(null);
    }
  };

  // Format currency
  const formatCurrency = (amount: number, currency: string) => {
    if (typeof amount !== "number" || isNaN(amount)) {
      return "$0.00";
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency.toUpperCase(),
      minimumFractionDigits: 2,
    });

    return formatter.format(amount / 100);
  };

  // Plan features
  const getPlanFeatures = (planType: any) => {
    // Ensure planType is a string
    const planTypeStr = String(planType || "").toUpperCase();

    const freeFeatures = [
      "100 automations",
      "3 integrations",
      "Email delivery",
      "Basic data formatting",
      "Community support",
    ];

    const basicFeatures = [
      "500 automations",
      "10 integrations",
      "Email delivery",
      "Basic data formatting",
      "Community support",
    ];

    const proFeatures = [
      "500 automations",
      "10 integrations",
      "Email & Slack delivery",
      "Advanced data formatting",
      "AI insights generation",
      "Priority support",
    ];

    const enterpriseFeatures = [
      "2500 automations",
      "50 integrations",
      "Custom delivery options",
      "Advanced AI processing",
      "Dedicated account manager",
      "Custom development",
      "SLA guarantees",
    ];

    if (planTypeStr.includes("FREE")) return freeFeatures;
    if (planTypeStr.includes("PRO")) return proFeatures;
    if (
      planTypeStr.includes("WORKAHOLIC") ||
      planTypeStr.includes("ENTERPRISE")
    )
      return enterpriseFeatures;
    if (planTypeStr.includes("PRODUCTIVE") || planTypeStr.includes("BASIC"))
      return basicFeatures;
    return basicFeatures;
  };

  // Sample features data
  const features: Feature[] = [
    {
      title: "One-Click Setup",
      description:
        "Record a sequence once, automate it forever. No coding or technical skills required.",
      icon: (
        <MousePointer className="h-10 w-10 text-primary dark:text-primary" />
      ),
    },
    {
      title: "Time-Based Scheduling",
      description:
        "Set automations to run daily, weekly, or monthly at your preferred times.",
      icon: <Clock className="h-10 w-10 text-primary dark:text-primary" />,
    },
    {
      title: "Enhanced Outputs",
      description:
        "AI processing transforms raw exports into formatted, presentation-ready reports.",
      icon: <FileText className="h-10 w-10 text-primary dark:text-primary" />,
    },
    {
      title: "Intelligent Insights",
      description:
        "Automatically identify trends, anomalies, and actionable insights in your data.",
      icon: <BarChart className="h-10 w-10 text-primary dark:text-primary" />,
    },
  ];

  // Automation types
  const automations: Automation[] = [
    {
      title: "Scheduled Exports",
      description:
        "Automatically export data from finance dashboards on your schedule.",
      icon: <Calendar className="h-10 w-10 text-primary dark:text-primary" />,
    },
    {
      title: "Recurring Reports",
      description:
        "Generate and distribute formatted reports to stakeholders automatically.",
      icon: <Repeat className="h-10 w-10 text-primary dark:text-primary" />,
    },
    {
      title: "Data Enhancement",
      description:
        "Apply AI processing to standardize and enrich your exported data.",
      icon: <Sparkles className="h-10 w-10 text-primary dark:text-primary" />,
    },
    {
      title: "Insight Generation",
      description:
        "Automatically extract key insights and anomalies from your financial data.",
      icon: <Lightbulb className="h-10 w-10 text-primary dark:text-primary" />,
    },
  ];

  // Business benefits
  const benefits: Benefit[] = [
    {
      title: "Save 45-90 Minutes Per Export",
      description:
        "Eliminate repetitive manual exports and free up valuable time for strategic work.",
      icon: <Clock className="h-10 w-10 text-primary dark:text-primary" />,
    },
    {
      title: "Zero Technical Setup",
      description:
        "No downloads, extensions, or IT involvement required. Works entirely in your browser.",
      icon: <Rocket className="h-10 w-10 text-primary dark:text-primary" />,
    },
    {
      title: "AI-Enhanced Outputs",
      description:
        "Get more than raw data—receive formatted reports with highlighted insights.",
      icon: <Bot className="h-10 w-10 text-primary dark:text-primary" />,
    },
    {
      title: "Enterprise-Grade Security",
      description:
        "OAuth integration with no credential storage and secure cloud infrastructure.",
      icon: <Shield className="h-10 w-10 text-primary dark:text-primary" />,
    },
  ];

  // Sample testimonials data
  const testimonials: Testimonial[] = [
    {
      id: 1,
      name: "Sarah Johnson",
      role: "CFO",
      company: "FinTech Solutions",
      content:
        "1Button has saved our finance team hours every week on financial dashboard exports. The automated reports are delivered right to my inbox, perfectly formatted.",
      avatar: "sarah",
    },
    {
      id: 2,
      name: "Michael Chen",
      role: "Financial Controller",
      company: "GrowthCorp",
      content:
        "I was spending 90 minutes every Monday morning on manual exports from our CRM. 1Button now does it automatically before I arrive, with better formatting than I could do manually.",
      avatar: "michael",
    },
    {
      id: 3,
      name: "Aisha Patel",
      role: "Operations Director",
      company: "Streamline Inc",
      content:
        "The AI insights have helped us identify spending patterns we never noticed before. And the best part? No IT department involvement was needed to set it up.",
      avatar: "aisha",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-muted/50 dark:from-background dark:to-background">
      {/* Header */}
      <header className="fixed top-0 z-50 w-full border-b border-border bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="container flex h-16 items-center justify-between">
          <div className="flex items-center space-x-4">
            <Link
              to="/"
              className="font-bold text-xl flex items-center text-foreground"
            >
              <MousePointer className="h-6 w-6 mr-2 text-primary" />
              1Button.io
            </Link>
          </div>
          <nav className="flex items-center space-x-4">
            <ThemeToggle />
            {user ? (
              <div className="flex items-center gap-4">
                <Link to="/dashboard">
                  <Button
                    variant="ghost"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Dashboard
                  </Button>
                </Link>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      className="gap-2 text-muted-foreground hover:text-foreground"
                    >
                      <Avatar className="h-8 w-8">
                        <AvatarImage
                          src={`https://api.dicebear.com/7.x/avataaars/svg?seed=${user.email}`}
                          alt={user.email || ""}
                        />
                        <AvatarFallback>
                          {user.email?.[0].toUpperCase()}
                        </AvatarFallback>
                      </Avatar>
                      <span className="hidden md:inline-block">
                        {user.email}
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    align="end"
                    className="bg-background border-border"
                  >
                    <DropdownMenuLabel className="text-foreground">
                      My Account
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator className="bg-border" />
                    <DropdownMenuItem className="text-muted-foreground hover:text-foreground focus:text-foreground">
                      <User className="mr-2 h-4 w-4" />
                      Profile
                    </DropdownMenuItem>
                    <DropdownMenuItem className="text-muted-foreground hover:text-foreground focus:text-foreground">
                      <Settings className="mr-2 h-4 w-4" />
                      Settings
                    </DropdownMenuItem>
                    <DropdownMenuSeparator className="bg-border" />
                    <DropdownMenuItem
                      onSelect={() => signOut()}
                      className="text-muted-foreground hover:text-foreground focus:text-foreground"
                    >
                      Log out
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            ) : (
              <>
                <Link to="/login">
                  <Button
                    variant="ghost"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Sign In
                  </Button>
                </Link>
                <Link to="/signup">
                  <Button className="bg-primary text-primary-foreground hover:bg-primary/90">
                    Get Started
                  </Button>
                </Link>
              </>
            )}
          </nav>
        </div>
      </header>

      <main>
        {/* Hero Section */}
        <section className="relative pt-24 pb-16 md:pt-32 md:pb-24">
          <div className="container px-4 mx-auto">
            <div className="flex flex-col lg:flex-row items-center gap-12">
              <div className="lg:w-1/2 space-y-8">
                <div>
                  <Badge className="mb-4 bg-primary/10 text-primary hover:bg-primary/20 border-none">
                    Enterprise Automation
                  </Badge>
                  <h1 className="text-4xl md:text-6xl font-bold tracking-tight text-foreground">
                    Automate Enterprise Software with One Button
                  </h1>
                </div>
                <p className="text-lg md:text-xl text-muted-foreground">
                  Save 45-90 minutes per action with 1Button.io — the web-based
                  solution that automates repetitive exports from finance, CRM,
                  and HR dashboards without downloads or extensions.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Link to="/signup">
                    <Button
                      size="lg"
                      className="bg-primary text-primary-foreground hover:bg-primary/90 w-full sm:w-auto"
                    >
                      Automate Your First Action
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </Button>
                  </Link>
                  <Button
                    variant="outline"
                    size="lg"
                    className="border-border text-muted-foreground hover:border-primary/50 hover:text-foreground w-full sm:w-auto"
                  >
                    <Calendar className="mr-2 h-4 w-4" />
                    Schedule a Demo
                  </Button>
                </div>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <CheckCircle2 className="h-4 w-4 text-primary" />
                  <span>No downloads required</span>
                  <Separator
                    orientation="vertical"
                    className="h-4 mx-2 bg-border"
                  />
                  <CheckCircle2 className="h-4 w-4 text-primary" />
                  <span>Works in any browser</span>
                  <Separator
                    orientation="vertical"
                    className="h-4 mx-2 bg-border"
                  />
                  <CheckCircle2 className="h-4 w-4 text-primary" />
                  <span>Free trial available</span>
                </div>
              </div>
              <div className="lg:w-1/2 relative">
                <div className="absolute -z-10 inset-0 bg-gradient-to-tr from-primary/10 via-primary/5 to-primary/20 dark:from-primary/20 dark:via-primary/10 dark:to-background rounded-3xl blur-2xl transform scale-110" />
                <div className="bg-card/80 backdrop-blur-sm border border-border rounded-xl shadow-xl overflow-hidden">
                  <div className="p-1 bg-gradient-to-r from-primary/20 via-primary/40 to-primary rounded-t-xl">
                    <div className="flex items-center gap-2 px-3 py-1">
                      <div className="h-3 w-3 rounded-full bg-red-500" />
                      <div className="h-3 w-3 rounded-full bg-yellow-500" />
                      <div className="h-3 w-3 rounded-full bg-green-500" />
                      <div className="ml-2 text-xs text-foreground font-medium">
                        1Button Automation
                      </div>
                    </div>
                  </div>
                  <div className="p-6">
                    <div className="space-y-4">
                      <div className="flex items-center gap-3">
                        <div className="h-10 w-10 rounded-full bg-primary/20 flex items-center justify-center">
                          <MousePointer className="h-5 w-5 text-primary" />
                        </div>
                        <div>
                          <h4 className="font-medium text-foreground">
                            Record Once
                          </h4>
                          <p className="text-sm text-muted-foreground">
                            Show us which buttons to click
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="h-10 w-10 rounded-full bg-primary/20 flex items-center justify-center">
                          <Calendar className="h-5 w-5 text-primary" />
                        </div>
                        <div>
                          <h4 className="font-medium text-foreground">
                            Schedule
                          </h4>
                          <p className="text-sm text-muted-foreground">
                            Set when and how often to run
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="h-10 w-10 rounded-full bg-primary/20 flex items-center justify-center">
                          <Sparkles className="h-5 w-5 text-primary" />
                        </div>
                        <div>
                          <h4 className="font-medium text-foreground">
                            Enhance
                          </h4>
                          <p className="text-sm text-muted-foreground">
                            AI processes and improves outputs
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="h-10 w-10 rounded-full bg-primary/20 flex items-center justify-center">
                          <FileText className="h-5 w-5 text-primary" />
                        </div>
                        <div>
                          <h4 className="font-medium text-foreground">
                            Deliver
                          </h4>
                          <p className="text-sm text-muted-foreground">
                            Get results via email or Slack
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Gradient orbs */}
          <div className="absolute top-1/4 left-0 -z-10 h-[300px] w-[300px] rounded-full bg-gray-200/60 blur-[100px]" />
          <div className="absolute bottom-0 right-0 -z-10 h-[300px] w-[300px] rounded-full bg-gray-400/40 blur-[100px]" />
        </section>

        {/* Features Section */}
        <section className="py-16 md:py-24 bg-muted/30 dark:bg-muted/10">
          <div className="container px-4 mx-auto">
            <div className="text-center mb-16">
              <Badge className="mb-4 bg-primary/10 text-primary hover:bg-primary/20 border-none">
                Features
              </Badge>
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4 text-foreground">
                Automate Enterprise Software with No Downloads
              </h2>
              <p className="text-muted-foreground max-w-[700px] mx-auto">
                1Button.io eliminates repetitive export tasks from finance, CRM,
                and HR dashboards, saving you hours every week with our
                web-based automation platform.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {features.map((feature, index) => (
                <Card
                  key={index}
                  className="border-border bg-gradient-to-b from-card to-card/80 shadow-md hover:shadow-lg transition-shadow"
                >
                  <CardHeader>
                    <div className="mb-4">{feature.icon}</div>
                    <CardTitle className="text-foreground">
                      {feature.title}
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-muted-foreground">
                      {feature.description}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Automation Types Section */}
        <section className="py-16 md:py-24 bg-background">
          <div className="container px-4 mx-auto">
            <div className="text-center mb-16">
              <Badge className="mb-4 bg-primary/10 text-primary hover:bg-primary/20 border-none">
                Automation Types
              </Badge>
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4 text-foreground">
                What Can You Automate?
              </h2>
              <p className="text-muted-foreground max-w-[700px] mx-auto">
                From finance, CRM, and HR dashboard exports to report
                generation, 1Button.io handles all your repetitive data export
                tasks.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {automations.map((automation, index) => (
                <Card
                  key={index}
                  className="border-border bg-gradient-to-b from-card to-card/80 shadow-md hover:shadow-lg transition-shadow"
                >
                  <CardHeader>
                    <div className="mb-4">{automation.icon}</div>
                    <CardTitle className="text-foreground">
                      {automation.title}
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-muted-foreground">
                      {automation.description}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-16 md:py-24 bg-muted/30 dark:bg-muted/10">
          <div className="container px-4 mx-auto">
            <div className="text-center mb-16">
              <Badge className="mb-4 bg-primary/10 text-primary hover:bg-primary/20 border-none">
                Benefits
              </Badge>
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4 text-foreground">
                Why Choose 1Button.io?
              </h2>
              <p className="text-muted-foreground max-w-[700px] mx-auto">
                Our platform delivers immediate ROI by eliminating repetitive
                tasks and enhancing your data.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {benefits.map((benefit, index) => (
                <Card
                  key={index}
                  className="border-border bg-gradient-to-b from-card to-card/80 shadow-md hover:shadow-lg transition-shadow"
                >
                  <CardHeader>
                    <div className="mb-4">{benefit.icon}</div>
                    <CardTitle className="text-foreground">
                      {benefit.title}
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-muted-foreground">
                      {benefit.description}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section className="py-16 md:py-24 bg-background">
          <div className="container px-4 mx-auto">
            <div className="text-center mb-16">
              <Badge className="mb-4 bg-primary/10 text-primary hover:bg-primary/20 border-none">
                Pricing
              </Badge>
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4 text-foreground">
                Simple, Transparent Pricing
              </h2>
              <p className="text-muted-foreground max-w-[700px] mx-auto">
                Choose the perfect plan for your automation needs. All plans
                include access to our core features. No hidden fees or
                surprises.
              </p>
            </div>

            {/* Error message removed - using static pricing cards instead */}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
              {/* Free Plan */}
              <Card className="flex flex-col h-full border-border bg-gradient-to-b from-card to-card/80 shadow-lg hover:shadow-xl transition-all relative">
                <CardHeader className="pb-4">
                  <CardDescription className="text-sm text-muted-foreground">
                    Free Forever
                  </CardDescription>
                  <div className="mt-4">
                    <span className="text-4xl font-bold text-foreground">
                      $0
                    </span>
                    <span className="text-muted-foreground">/forever</span>
                  </div>
                </CardHeader>
                <CardContent className="flex-grow">
                  <Separator className="my-4 bg-border" />
                  <ul className="space-y-3">
                    {getPlanFeatures("FREE").map((feature, index) => (
                      <li
                        key={index}
                        className="flex items-start text-muted-foreground"
                      >
                        <CheckCircle2 className="h-5 w-5 text-primary mr-2 flex-shrink-0 mt-0.5" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardFooter>
                  <Link to="/signup">
                    <Button className="w-full bg-primary text-primary-foreground hover:bg-primary/90">
                      Get Started
                      <ChevronRight className="ml-1 h-4 w-4" />
                    </Button>
                  </Link>
                </CardFooter>
              </Card>

              {/* Productive Plan */}
              <Card className="flex flex-col h-full border-border bg-gradient-to-b from-card to-card/80 shadow-lg hover:shadow-xl transition-all relative">
                <CardHeader className="pb-4">
                  <CardDescription className="text-sm text-muted-foreground">
                    Productive
                  </CardDescription>
                  <div className="mt-4">
                    <span className="text-4xl font-bold text-foreground">
                      $29
                    </span>
                    <span className="text-muted-foreground">/month</span>
                  </div>
                </CardHeader>
                <CardContent className="flex-grow">
                  <Separator className="my-4 bg-border" />
                  <ul className="space-y-3">
                    {getPlanFeatures("PRODUCTIVE").map((feature, index) => (
                      <li
                        key={index}
                        className="flex items-start text-muted-foreground"
                      >
                        <CheckCircle2 className="h-5 w-5 text-primary mr-2 flex-shrink-0 mt-0.5" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardFooter>
                  <Button
                    className="w-full bg-primary text-primary-foreground hover:bg-primary/90"
                    onClick={() => handleCheckout("price_productive")}
                    disabled={isLoading}
                  >
                    {isLoading && processingPlanId === "price_productive" ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Processing...
                      </>
                    ) : (
                      <>
                        Subscribe Now
                        <ChevronRight className="ml-1 h-4 w-4" />
                      </>
                    )}
                  </Button>
                </CardFooter>
              </Card>

              {/* Workaholic Plan */}
              <Card className="flex flex-col h-full border-border bg-gradient-to-b from-card to-card/80 shadow-lg hover:shadow-xl transition-all relative">
                <CardHeader className="pb-4">
                  <CardDescription className="text-sm text-muted-foreground">
                    Workaholic
                  </CardDescription>
                  <div className="mt-4">
                    <span className="text-4xl font-bold text-foreground">
                      $79
                    </span>
                    <span className="text-muted-foreground">/month</span>
                  </div>
                </CardHeader>
                <CardContent className="flex-grow">
                  <Separator className="my-4 bg-border" />
                  <ul className="space-y-3">
                    {getPlanFeatures("WORKAHOLIC").map((feature, index) => (
                      <li
                        key={index}
                        className="flex items-start text-muted-foreground"
                      >
                        <CheckCircle2 className="h-5 w-5 text-primary mr-2 flex-shrink-0 mt-0.5" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardFooter>
                  <Button
                    className="w-full bg-primary text-primary-foreground hover:bg-primary/90"
                    onClick={() => handleCheckout("price_workaholic")}
                    disabled={isLoading}
                  >
                    {isLoading && processingPlanId === "price_workaholic" ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Processing...
                      </>
                    ) : (
                      <>
                        Subscribe Now
                        <ChevronRight className="ml-1 h-4 w-4" />
                      </>
                    )}
                  </Button>
                </CardFooter>
              </Card>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="py-16 md:py-24 bg-muted/30 dark:bg-muted/10">
          <div className="container px-4 mx-auto">
            <div className="text-center mb-16">
              <Badge className="mb-4 bg-primary/10 text-primary hover:bg-primary/20 border-none">
                Testimonials
              </Badge>
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4 text-foreground">
                Loved by Finance Teams
              </h2>
              <p className="text-muted-foreground max-w-[700px] mx-auto">
                See what our users have to say about 1Button.io automation
                platform.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {testimonials.map((testimonial) => (
                <Card
                  key={testimonial.id}
                  className="border-border bg-gradient-to-b from-card to-card/80 shadow-md"
                >
                  <CardHeader className="pb-2">
                    <div className="flex items-center gap-4">
                      <Avatar>
                        <AvatarImage
                          src={`https://api.dicebear.com/7.x/avataaars/svg?seed=${testimonial.avatar}`}
                          alt={testimonial.name}
                        />
                        <AvatarFallback>{testimonial.name[0]}</AvatarFallback>
                      </Avatar>
                      <div>
                        <CardTitle className="text-base text-foreground">
                          {testimonial.name}
                        </CardTitle>
                        <CardDescription className="text-muted-foreground">
                          {testimonial.role} at {testimonial.company}
                        </CardDescription>
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <div className="flex mb-2">
                      {[...Array(5)].map((_, i) => (
                        <Star
                          key={i}
                          className="h-4 w-4 fill-primary text-primary"
                        />
                      ))}
                    </div>
                    <p className="text-muted-foreground">
                      {testimonial.content}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-16 md:py-24">
          <div className="container px-4 mx-auto">
            <div className="bg-gradient-to-r from-muted/50 to-background rounded-3xl p-8 md:p-12 shadow-xl border border-border">
              <div className="max-w-3xl mx-auto text-center">
                <h2 className="text-3xl md:text-4xl font-bold mb-6 text-foreground">
                  Ready to Save Hours Every Week?
                </h2>
                <p className="text-lg md:text-xl mb-8 text-muted-foreground">
                  Join finance teams who are automating repetitive tasks and
                  getting more done with 1Button.io.
                </p>
                <div className="flex flex-col sm:flex-row justify-center gap-4">
                  <Link to="/signup">
                    <Button
                      size="lg"
                      className="bg-primary text-primary-foreground hover:bg-primary/90 w-full sm:w-auto"
                    >
                      Start Automating Now
                    </Button>
                  </Link>
                  <Button
                    variant="outline"
                    size="lg"
                    className="border-border text-muted-foreground hover:border-primary/50 hover:text-foreground w-full sm:w-auto"
                  >
                    Schedule a Demo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Us Section */}
        <section className="py-16 md:py-24 bg-muted/30 dark:bg-muted/10">
          <div className="container px-4 mx-auto">
            <div className="text-center mb-8">
              <Badge className="mb-4 bg-primary/10 text-primary hover:bg-primary/20 border-none">
                Contact Us
              </Badge>
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4 text-foreground">
                Get in Touch
              </h2>
              <p className="text-muted-foreground max-w-[700px] mx-auto mb-8">
                Have questions about 1Button.io or need assistance? Our team is
                here to help.
              </p>
            </div>

            <div className="max-w-2xl mx-auto bg-card border border-border rounded-xl p-8 shadow-md">
              <div className="flex flex-col items-center justify-center space-y-6">
                <div className="h-16 w-16 rounded-full bg-primary/20 flex items-center justify-center">
                  <Mail className="h-8 w-8 text-primary" />
                </div>
                <h3 className="text-xl font-semibold text-foreground">
                  Email Us
                </h3>
                <p className="text-muted-foreground text-center">
                  For inquiries, support, or to learn more about our services,
                  reach out to us at:
                </p>
                <a
                  href="mailto:hello@1button.io"
                  className="text-primary hover:text-primary/80 text-lg font-medium flex items-center gap-2"
                >
                  <Mail className="h-5 w-5" />
                  hello@1button.io
                </a>
                <p className="text-muted-foreground text-center text-sm">
                  We typically respond within 24 business hours.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-background border-t border-border py-12">
        <div className="container px-4 mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <Link
                to="/"
                className="font-bold text-xl flex items-center mb-4 text-foreground"
              >
                <MousePointer className="h-5 w-5 mr-2 text-primary" />
                1Button.io
              </Link>
              <p className="text-muted-foreground mb-4">
                Enterprise automation platform that saves time by eliminating
                repetitive tasks in business software.
              </p>
              <div className="flex space-x-4">
                <Button
                  variant="ghost"
                  size="icon"
                  className="rounded-full text-muted-foreground hover:text-foreground"
                >
                  <Github className="h-5 w-5" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  className="rounded-full text-muted-foreground hover:text-foreground"
                >
                  <Twitter className="h-5 w-5" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  className="rounded-full text-muted-foreground hover:text-foreground"
                >
                  <Instagram className="h-5 w-5" />
                </Button>
              </div>
            </div>

            <div>
              <h3 className="font-medium text-lg mb-4 text-foreground">
                Product
              </h3>
              <ul className="space-y-3">
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Features
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Integrations
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Roadmap
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="font-medium text-lg mb-4 text-foreground">
                Resources
              </h3>
              <ul className="space-y-3">
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Documentation
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Tutorials
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Case Studies
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Support
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="font-medium text-lg mb-4 text-foreground">
                Company
              </h3>
              <ul className="space-y-3">
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Careers
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-muted-foreground hover:text-foreground"
                  >
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <Separator className="my-8 bg-border" />

          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-muted-foreground">
              © {new Date().getFullYear()} 1Button.io. All rights reserved.
            </p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              <Link
                to="#"
                className="text-sm text-muted-foreground hover:text-foreground"
              >
                Privacy
              </Link>
              <Link
                to="#"
                className="text-sm text-muted-foreground hover:text-foreground"
              >
                Terms
              </Link>
              <Link
                to="#"
                className="text-sm text-muted-foreground hover:text-foreground"
              >
                Cookies
              </Link>
            </div>
          </div>
        </div>
      </footer>
      <Toaster />
    </div>
  );
}
