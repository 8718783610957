import React, { useState, useEffect } from "react";
import TopNavigation from "./TopNavigation";
import Sidebar from "./Sidebar";
import Header from "../../layout/Header";
import { Menu, ChevronLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

interface DashboardLayoutProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
  actions?: React.ReactNode;
  showBackButton?: boolean;
}

const DashboardLayout = ({
  children,
  title = "Dashboard",
  description = "Manage your automations and workflows",
  actions,
  showBackButton = false,
}: DashboardLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Close sidebar on mobile when navigating
  useEffect(() => {
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
    }
  }, [location.pathname]);

  // Breadcrumbs are now handled in TopNavigation

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="w-full">
        <Header />
      </div>
      <TopNavigation onMenuClick={() => setSidebarOpen(!sidebarOpen)} />

      <div className="flex pt-20">
        {/* Reduced padding-top to bring content closer to header and navigation */}
        <div
          className={`${sidebarOpen ? "block" : "hidden"} md:block fixed left-0 top-16 h-[calc(100vh-4rem)]`}
        >
          <Sidebar
            activeItem={title}
            onItemClick={() => setSidebarOpen(false)}
          />
        </div>
        <main
          className={`flex-1 overflow-auto p-4 md:p-6 transition-all ${sidebarOpen ? "md:ml-[260px]" : "ml-0"}`}
        >
          <div className="mb-6">
            <div className="flex items-center justify-between flex-wrap gap-4">
              <div className="flex items-start gap-3">
                {(showBackButton ||
                  (location.pathname !== "/dashboard" &&
                    !location.pathname.includes(
                      "dashboard/automations/create",
                    ))) && (
                  <Button
                    variant="ghost"
                    size="icon"
                    className="mt-1"
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeft className="h-5 w-5" />
                  </Button>
                )}

                <div>
                  <div className="flex items-center gap-3">
                    <Button
                      variant="ghost"
                      size="icon"
                      className="md:hidden"
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                      <Menu className="h-5 w-5" />
                    </Button>
                    <h1 className="text-xl md:text-2xl font-bold text-gray-800">
                      {title}
                    </h1>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">{description}</p>
                </div>
              </div>

              {actions && (
                <div className="flex items-center gap-2">{actions}</div>
              )}
            </div>
          </div>

          <div className="bg-white rounded-lg border border-gray-200 shadow-sm p-4 md:p-6">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
