import React from "react";
import DashboardLayout from "../dashboard/layout/DashboardLayout";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

const Analytics = () => {
  return (
    <DashboardLayout
      title="Analytics"
      description="Track and analyze your automation performance"
    >
      <div className="flex justify-end mb-6">
        <Select defaultValue="30days">
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select period" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="7days">Last 7 days</SelectItem>
            <SelectItem value="30days">Last 30 days</SelectItem>
            <SelectItem value="90days">Last 90 days</SelectItem>
            <SelectItem value="year">Last year</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-lg font-medium">Total Runs</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold">128</div>
            <div className="text-sm text-green-600 mt-1">
              +12% from last period
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-lg font-medium">Success Rate</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold">96.5%</div>
            <div className="text-sm text-green-600 mt-1">
              +2.3% from last period
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-lg font-medium">Time Saved</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold">42.5h</div>
            <div className="text-sm text-green-600 mt-1">
              +8.2h from last period
            </div>
          </CardContent>
        </Card>
      </div>

      <Tabs defaultValue="overview">
        <TabsList className="mb-6">
          <TabsTrigger value="overview">Overview</TabsTrigger>
          <TabsTrigger value="automations">By Automation</TabsTrigger>
          <TabsTrigger value="platforms">By Platform</TabsTrigger>
        </TabsList>

        <TabsContent value="overview" className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Automation Runs Over Time</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80 w-full bg-gray-100 rounded-md flex items-center justify-center">
                <p className="text-gray-500">Chart: Automation runs by day</p>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Success vs. Failure Rate</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80 w-full bg-gray-100 rounded-md flex items-center justify-center">
                <p className="text-gray-500">
                  Chart: Success/failure pie chart
                </p>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="automations" className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Performance by Automation</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80 w-full bg-gray-100 rounded-md flex items-center justify-center">
                <p className="text-gray-500">
                  Chart: Automation performance comparison
                </p>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Most Frequently Run Automations</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80 w-full bg-gray-100 rounded-md flex items-center justify-center">
                <p className="text-gray-500">
                  Chart: Automation frequency bar chart
                </p>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="platforms" className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Automation Distribution by Platform</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80 w-full bg-gray-100 rounded-md flex items-center justify-center">
                <p className="text-gray-500">
                  Chart: Platform distribution pie chart
                </p>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Success Rate by Platform</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80 w-full bg-gray-100 rounded-md flex items-center justify-center">
                <p className="text-gray-500">
                  Chart: Platform success rate comparison
                </p>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </DashboardLayout>
  );
};

export default Analytics;
