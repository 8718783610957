import React, { useState, useEffect } from "react";
import DashboardLayout from "../dashboard/layout/DashboardLayout";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Badge } from "../ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import {
  Clock,
  Edit,
  MoreHorizontal,
  Play,
  Plus,
  Trash,
  Loader2,
  Search,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import { useToast } from "../ui/use-toast";
import { Input } from "../ui/input";

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY,
);

interface Automation {
  id: string;
  name: string;
  description: string;
  target_platform: string;
  status: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  next_run?: string;
  last_run?: string;
  steps_count?: number;
}

const AutomationCard = ({
  automation,
  onRunAutomation,
  isRunning,
}: {
  automation: Automation;
  onRunAutomation: (id: string) => void;
  isRunning: boolean;
}) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleEdit = () => {
    navigate(`/dashboard/automations/edit/${automation.id}`);
  };

  const handleDelete = async () => {
    try {
      const { error } = await supabase
        .from("automations")
        .delete()
        .eq("id", automation.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Automation deleted successfully",
      });

      // Refresh the page to update the list
      window.location.reload();
    } catch (error) {
      console.error("Error deleting automation:", error);
      toast({
        title: "Error",
        description: "Failed to delete automation",
        variant: "destructive",
      });
    }
  };

  // Format platform name to be more readable
  const getPlatformName = (platformId: string) => {
    const platforms: Record<string, string> = {
      finance: "Finance Dashboard",
      salesforce: "Salesforce",
      hubspot: "HubSpot",
      excel: "Excel",
      google_sheets: "Google Sheets",
      linkedin: "LinkedIn",
    };

    return platforms[platformId] || platformId;
  };

  return (
    <Card>
      <CardHeader className="pb-2">
        <div className="flex justify-between items-start">
          <div>
            <CardTitle className="text-lg font-medium">
              {automation.name}
            </CardTitle>
            <CardDescription className="mt-1">
              {automation.description}
            </CardDescription>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="h-8 w-8">
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={handleEdit}>
                <Edit className="mr-2 h-4 w-4" /> Edit
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => onRunAutomation(automation.id)}>
                <Play className="mr-2 h-4 w-4" /> Run now
              </DropdownMenuItem>
              <DropdownMenuItem className="text-red-600" onClick={handleDelete}>
                <Trash className="mr-2 h-4 w-4" /> Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-wrap gap-2 mb-4">
          <Badge variant="outline">
            {getPlatformName(automation.target_platform)}
          </Badge>
          <Badge
            variant={automation.status === "active" ? "default" : "secondary"}
          >
            {automation.status === "active" ? "Active" : "Draft"}
          </Badge>
          <Badge variant="outline" className="flex items-center gap-1">
            <Clock className="h-3 w-3" /> {automation.steps_count || 0} steps
          </Badge>
        </div>
        <div className="text-sm">
          <div className="flex justify-between text-gray-500 mb-1">
            <span>Next run:</span>
            <span>{automation.next_run || "Not scheduled"}</span>
          </div>
          <div className="flex justify-between text-gray-500">
            <span>Last run:</span>
            <span>{automation.last_run || "Never"}</span>
          </div>
        </div>
      </CardContent>
      <CardFooter className="pt-0">
        <div className="flex justify-between w-full">
          <Button variant="outline" size="sm" onClick={handleEdit}>
            <Edit className="mr-2 h-4 w-4" /> Edit
          </Button>
          <Button
            size="sm"
            onClick={() => onRunAutomation(automation.id)}
            disabled={isRunning}
          >
            {isRunning ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" /> Running...
              </>
            ) : (
              <>
                <Play className="mr-2 h-4 w-4" /> Run now
              </>
            )}
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

const Automations = () => {
  const [automations, setAutomations] = useState<Automation[]>([]);
  const [filteredAutomations, setFilteredAutomations] = useState<Automation[]>(
    [],
  );
  const [loading, setLoading] = useState(true);
  const [runningAutomation, setRunningAutomation] = useState<string | null>(
    null,
  );
  const [searchQuery, setSearchQuery] = useState("");
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    fetchAutomations();
  }, []);

  useEffect(() => {
    // Filter automations based on search query
    if (searchQuery.trim() === "") {
      setFilteredAutomations(automations);
    } else {
      const query = searchQuery.toLowerCase();
      const filtered = automations.filter(
        (automation) =>
          automation.name.toLowerCase().includes(query) ||
          automation.description.toLowerCase().includes(query) ||
          automation.target_platform.toLowerCase().includes(query),
      );
      setFilteredAutomations(filtered);
    }
  }, [searchQuery, automations]);

  useEffect(() => {
    // Check for status filter in URL
    const statusFilter = searchParams.get("status");
    if (statusFilter && automations.length > 0) {
      const filtered = automations.filter((a) => a.status === statusFilter);
      setFilteredAutomations(filtered);
    }
  }, [searchParams, automations]);

  const fetchAutomations = async () => {
    try {
      setLoading(true);

      // Fetch automations
      const { data: automationsData, error: automationsError } = await supabase
        .from("automations")
        .select("*")
        .order("created_at", { ascending: false });

      if (automationsError) throw automationsError;

      // Fetch steps count for each automation
      const automationsWithSteps = await Promise.all(
        automationsData.map(async (automation) => {
          const { count, error: stepsError } = await supabase
            .from("automation_steps")
            .select("*", { count: "exact", head: true })
            .eq("automation_id", automation.id);

          if (stepsError) throw stepsError;

          // Fetch schedule information
          const { data: scheduleData, error: scheduleError } = await supabase
            .from("automation_schedules")
            .select("*")
            .eq("automation_id", automation.id)
            .maybeSingle();

          if (scheduleError) throw scheduleError;

          // Fetch last run information
          const { data: lastRunData, error: lastRunError } = await supabase
            .from("automation_runs")
            .select("*")
            .eq("automation_id", automation.id)
            .order("created_at", { ascending: false })
            .limit(1)
            .maybeSingle();

          if (lastRunError) throw lastRunError;

          return {
            ...automation,
            steps_count: count || 0,
            next_run: scheduleData?.next_run_at
              ? new Date(scheduleData.next_run_at).toLocaleString()
              : undefined,
            last_run: lastRunData?.completed_at
              ? new Date(lastRunData.completed_at).toLocaleString()
              : undefined,
          };
        }),
      );

      setAutomations(automationsWithSteps);
      setFilteredAutomations(automationsWithSteps);
    } catch (error) {
      console.error("Error fetching automations:", error);
      toast({
        title: "Error",
        description: "Failed to load automations",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const runAutomation = async (id: string) => {
    try {
      setRunningAutomation(id);

      const { data, error } = await supabase.functions.invoke(
        "supabase-functions-run-automation",
        {
          body: { automationId: id },
        },
      );

      if (error) throw error;

      toast({
        title: "Success",
        description: "Automation executed successfully",
      });

      // Refresh the automations list to show updated run information
      fetchAutomations();
    } catch (error) {
      console.error("Error running automation:", error);
      toast({
        title: "Error",
        description: "Failed to run automation",
        variant: "destructive",
      });
    } finally {
      setRunningAutomation(null);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <DashboardLayout
      title="Automations"
      description="Create and manage your automated workflows"
    >
      <div className="flex justify-between items-center mb-6">
        <div className="relative w-full max-w-xs">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
          <Input
            placeholder="Search automations..."
            className="pl-8 h-9 text-sm border-gray-200 focus:border-gray-300"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <Button onClick={() => navigate("/dashboard/automations/create")}>
          <Plus className="mr-2 h-4 w-4" /> New Automation
        </Button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
          <span className="ml-2 text-lg">Loading automations...</span>
        </div>
      ) : (
        <Tabs defaultValue="all">
          <TabsList className="mb-6">
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="active">Active</TabsTrigger>
            <TabsTrigger value="draft">Drafts</TabsTrigger>
          </TabsList>

          <TabsContent value="all" className="mt-0">
            {filteredAutomations.length === 0 ? (
              <div className="text-center py-12 bg-gray-50 rounded-lg">
                <h3 className="text-lg font-medium mb-2">
                  {searchQuery
                    ? "No matching automations found"
                    : "No automations found"}
                </h3>
                <p className="text-gray-500 mb-4">
                  {searchQuery
                    ? "Try a different search term"
                    : "Create your first automation to get started"}
                </p>
                <Button
                  onClick={() => navigate("/dashboard/automations/create")}
                >
                  <Plus className="mr-2 h-4 w-4" /> Create Automation
                </Button>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredAutomations.map((automation) => (
                  <AutomationCard
                    key={automation.id}
                    automation={automation}
                    onRunAutomation={runAutomation}
                    isRunning={runningAutomation === automation.id}
                  />
                ))}
              </div>
            )}
          </TabsContent>

          <TabsContent value="active" className="mt-0">
            {filteredAutomations.filter((a) => a.status === "active").length ===
            0 ? (
              <div className="text-center py-12 bg-gray-50 rounded-lg">
                <h3 className="text-lg font-medium mb-2">
                  No active automations
                </h3>
                <p className="text-gray-500 mb-4">
                  Activate an automation or create a new one
                </p>
                <Button
                  onClick={() => navigate("/dashboard/automations/create")}
                >
                  <Plus className="mr-2 h-4 w-4" /> Create Automation
                </Button>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredAutomations
                  .filter((a) => a.status === "active")
                  .map((automation) => (
                    <AutomationCard
                      key={automation.id}
                      automation={automation}
                      onRunAutomation={runAutomation}
                      isRunning={runningAutomation === automation.id}
                    />
                  ))}
              </div>
            )}
          </TabsContent>

          <TabsContent value="draft" className="mt-0">
            {filteredAutomations.filter((a) => a.status === "draft").length ===
            0 ? (
              <div className="text-center py-12 bg-gray-50 rounded-lg">
                <h3 className="text-lg font-medium mb-2">
                  No draft automations
                </h3>
                <p className="text-gray-500 mb-4">
                  Create a new automation to get started
                </p>
                <Button
                  onClick={() => navigate("/dashboard/automations/create")}
                >
                  <Plus className="mr-2 h-4 w-4" /> Create Automation
                </Button>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredAutomations
                  .filter((a) => a.status === "draft")
                  .map((automation) => (
                    <AutomationCard
                      key={automation.id}
                      automation={automation}
                      onRunAutomation={runAutomation}
                      isRunning={runningAutomation === automation.id}
                    />
                  ))}
              </div>
            )}
          </TabsContent>
        </Tabs>
      )}
    </DashboardLayout>
  );
};

export default Automations;
