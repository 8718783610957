import { User } from "@supabase/supabase-js";
import { supabase } from "./supabase";

export interface UserProfile {
  id: string;
  email?: string;
  full_name?: string;
  avatar_url?: string;
  created_at?: string;
  updated_at?: string;
  subscription?: string | null;
  credits?: number;
  user_id?: string;
  token_identifier?: string;
}

/**
 * Ensures a user exists in the public.users table
 * This is called after authentication to make sure we have a user profile
 */
export async function ensureUserExists(
  user: User,
): Promise<UserProfile | null> {
  try {
    if (!user?.id) {
      console.error("Invalid user data - missing ID", user);
      return null;
    }

    console.log("Ensuring user exists in public.users table:", user.id);

    // First check if user already exists
    const { data: existingUser, error: fetchError } = await supabase
      .from("users")
      .select("*")
      .eq("id", user.id)
      .single();

    if (fetchError && fetchError.code !== "PGRST116") {
      // PGRST116 is the error code for no rows returned
      console.error("Error checking if user exists:", fetchError);
    }

    if (existingUser) {
      // User exists, update their record with latest data
      console.log("User exists, updating record");
      const { data: updatedUser, error: updateError } = await supabase
        .from("users")
        .update({
          email: user.email || "",
          full_name: user.user_metadata?.full_name || "",
          avatar_url: user.user_metadata?.avatar_url || "",
          updated_at: new Date().toISOString(),
        })
        .eq("id", user.id)
        .select("*")
        .single();

      if (updateError) {
        console.error("Error updating user record:", updateError);
        return existingUser as UserProfile;
      }

      console.log("User record updated successfully");
      return updatedUser as UserProfile;
    } else {
      // User doesn't exist, insert new record
      console.log("User doesn't exist, creating new record");
      const userData = {
        id: user.id,
        email: user.email || "",
        full_name: user.user_metadata?.full_name || "",
        avatar_url: user.user_metadata?.avatar_url || "",
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        token_identifier: user.id,
        user_id: user.id,
        credits: 0,
        subscription: null,
      };

      const { data: newUser, error: insertError } = await supabase
        .from("users")
        .insert([userData])
        .select("*")
        .single();

      if (insertError) {
        console.error("Error inserting user record:", insertError);

        // If insert fails, try upsert as a last resort
        const { data: upsertedUser, error: upsertError } = await supabase
          .from("users")
          .upsert([userData], { onConflict: "id" })
          .select("*")
          .single();

        if (upsertError) {
          console.error("Fallback upsert also failed:", upsertError);
          return null;
        }

        console.log("User record upserted successfully");
        return upsertedUser as UserProfile;
      }

      console.log("User record inserted successfully");
      return newUser as UserProfile;
    }
  } catch (error) {
    console.error("Error in ensureUserExists:", error);
    return null;
  }
}

/**
 * Gets a user profile from the public.users table
 */
export async function getUserProfile(
  userId: string,
): Promise<UserProfile | null> {
  try {
    const { data, error } = await supabase
      .from("users")
      .select("*")
      .eq("id", userId)
      .single();

    if (error) {
      console.error("Error fetching user profile:", error);
      return null;
    }

    return data as UserProfile;
  } catch (error) {
    console.error("Error in getUserProfile:", error);
    return null;
  }
}

/**
 * Updates a user profile in the public.users table
 */
export async function updateUserProfile(
  userId: string,
  userData: Partial<UserProfile>,
): Promise<UserProfile | null> {
  try {
    // Update the users table
    const { data, error } = await supabase
      .from("users")
      .update({
        ...userData,
        updated_at: new Date().toISOString(),
      })
      .eq("id", userId)
      .select("*")
      .single();

    if (error) {
      console.error("Error updating user profile:", error);
      return null;
    }

    // If full_name is being updated, also update the auth.users metadata
    if (userData.full_name) {
      const { error: metadataError } = await supabase.auth.updateUser({
        data: { full_name: userData.full_name },
      });

      if (metadataError) {
        console.error("Error updating user metadata:", metadataError);
      }
    }

    return data as UserProfile;
  } catch (error) {
    console.error("Error in updateUserProfile:", error);
    return null;
  }
}

/**
 * Deletes a user from both auth.users and public.users tables
 * This requires admin privileges and should be called from a secure server function
 */
export async function deleteUser(userId: string): Promise<boolean> {
  try {
    const { error } = await supabase.functions.invoke("delete-user", {
      body: { userId },
    });

    if (error) {
      console.error("Error deleting user:", error);
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error in deleteUser:", error);
    return false;
  }
}
