import React from "react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { Link, useLocation } from "react-router-dom";
import {
  Home,
  LayoutDashboard,
  Zap,
  BarChart2,
  Settings,
  HelpCircle,
  PlusCircle,
  History,
  FileText,
  Bell,
  Users,
  Briefcase,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Badge } from "@/components/ui/badge";

interface NavItem {
  icon: React.ReactNode;
  label: string;
  href: string;
  badge?: string;
  tooltip?: string;
}

interface SidebarProps {
  activeItem?: string;
  onItemClick?: (label: string) => void;
}

const mainNavItems: NavItem[] = [
  { icon: <Home size={18} />, label: "Home", href: "/dashboard" },
  {
    icon: <LayoutDashboard size={18} />,
    label: "Dashboard",
    href: "/dashboard",
    tooltip: "Overview of your automation activities",
  },
  {
    icon: <Zap size={18} />,
    label: "Automations",
    href: "/dashboard/automations",
    badge: "5",
    tooltip: "Manage your existing automations",
  },
  {
    icon: <PlusCircle size={18} />,
    label: "Create Automation",
    href: "/dashboard/automations/create",
    tooltip: "Build a new automation workflow",
  },
  {
    icon: <BarChart2 size={18} />,
    label: "Analytics",
    href: "/dashboard/analytics",
    tooltip: "View performance metrics and insights",
  },
  {
    icon: <Bell size={18} />,
    label: "Notifications",
    href: "/dashboard/notifications",
    badge: "3",
    tooltip: "View system and automation alerts",
  },
];

const resourceNavItems: NavItem[] = [
  {
    icon: <History size={18} />,
    label: "History",
    href: "/dashboard/history",
    tooltip: "View past automation runs",
  },
  {
    icon: <FileText size={18} />,
    label: "Documentation",
    href: "/dashboard/docs",
    tooltip: "Learn how to use 1Button.io",
  },
  {
    icon: <Briefcase size={18} />,
    label: "Templates",
    href: "/dashboard/templates",
    tooltip: "Pre-built automation templates",
  },
];

const bottomItems: NavItem[] = [
  {
    icon: <Users size={18} />,
    label: "Team",
    href: "/dashboard/team",
    tooltip: "Manage team members and permissions",
  },
  {
    icon: <Settings size={18} />,
    label: "Settings",
    href: "/dashboard/settings",
    tooltip: "Configure your account settings",
  },
  {
    icon: <HelpCircle size={18} />,
    label: "Help & Support",
    href: "/dashboard/support",
    tooltip: "Get help with 1Button.io",
  },
];

const Sidebar = ({
  activeItem = "Dashboard",
  onItemClick = () => {},
}: SidebarProps) => {
  const location = useLocation();

  // Determine active item based on current path
  const getIsActive = (href: string) => {
    return (
      location.pathname === href || location.pathname.startsWith(href + "/")
    );
  };

  const renderNavItem = (item: NavItem) => {
    const isActive = getIsActive(item.href) || item.label === activeItem;

    return (
      <TooltipProvider key={item.label}>
        <Tooltip delayDuration={300}>
          <TooltipTrigger asChild>
            <Link to={item.href} className="block w-full">
              <Button
                variant={isActive ? "secondary" : "ghost"}
                className="w-full justify-start gap-2 text-sm h-10 relative"
                onClick={() => onItemClick(item.label)}
              >
                {item.icon}
                <span>{item.label}</span>
                {item.badge && (
                  <Badge
                    variant="secondary"
                    className="ml-auto h-5 min-w-5 px-1 rounded-full bg-primary text-white text-xs flex items-center justify-center"
                  >
                    {item.badge}
                  </Badge>
                )}
              </Button>
            </Link>
          </TooltipTrigger>
          {item.tooltip && (
            <TooltipContent side="right">
              <p>{item.tooltip}</p>
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    );
  };

  return (
    <div className="w-[240px] h-[calc(100vh-8rem)] fixed top-32 left-0 border-r border-gray-200 bg-white flex flex-col z-30">
      <ScrollArea className="flex-1 px-3 py-4">
        <div className="space-y-1">{mainNavItems.map(renderNavItem)}</div>

        <Separator className="my-4" />

        <div className="space-y-1">
          <h3 className="text-xs font-medium px-3 py-2 text-gray-500">
            AUTOMATION STATUS
          </h3>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to="/dashboard/automations?status=active"
                  className="block w-full"
                >
                  <Button
                    variant="ghost"
                    className="w-full justify-start gap-2 text-sm h-9"
                  >
                    <span className="h-2 w-2 rounded-full bg-green-500"></span>
                    Active
                    <Badge variant="outline" className="ml-auto">
                      3
                    </Badge>
                  </Button>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right">
                <p>View active automations</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to="/dashboard/automations?status=failed"
                  className="block w-full"
                >
                  <Button
                    variant="ghost"
                    className="w-full justify-start gap-2 text-sm h-9"
                  >
                    <span className="h-2 w-2 rounded-full bg-red-500"></span>
                    Failed
                    <Badge variant="outline" className="ml-auto">
                      1
                    </Badge>
                  </Button>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right">
                <p>View failed automations</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to="/dashboard/automations?status=pending"
                  className="block w-full"
                >
                  <Button
                    variant="ghost"
                    className="w-full justify-start gap-2 text-sm h-9"
                  >
                    <span className="h-2 w-2 rounded-full bg-yellow-500"></span>
                    Pending
                    <Badge variant="outline" className="ml-auto">
                      2
                    </Badge>
                  </Button>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right">
                <p>View pending automations</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        <Separator className="my-4" />

        <div className="space-y-1">
          <h3 className="text-xs font-medium px-3 py-2 text-gray-500">
            RESOURCES
          </h3>
          {resourceNavItems.map(renderNavItem)}
        </div>
      </ScrollArea>

      <div className="p-3 mt-auto border-t border-gray-200">
        {bottomItems.map(renderNavItem)}
      </div>
    </div>
  );
};

export default Sidebar;
