import React from "react";
import DashboardLayout from "../dashboard/layout/DashboardLayout";
import DashboardGrid from "../dashboard/DashboardGrid";
import ActivityFeed from "../dashboard/ActivityFeed";
import TaskBoard from "../dashboard/TaskBoard";

const Dashboard = () => {
  return (
    <DashboardLayout
      title="Dashboard"
      description="Welcome back! Here's an overview of your automations."
    >
      <DashboardGrid />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6">
        <div className="lg:col-span-2">
          <ActivityFeed />
        </div>
        <div>
          <TaskBoard />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
