import { useEffect, useState } from "react";
import { User } from "@supabase/supabase-js";
import { supabase } from "./supabase";
import { AuthContext } from "./auth-context";
import {
  signIn,
  signUp,
  signInWithGoogle,
  signOut,
  resetPassword,
  updatePassword,
  getUserProfile,
  updateUserProfile,
  deleteUser,
} from "./auth-service";
import { ensureUserExists } from "./user-management";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        // Check active sessions and sets the user
        const { data: sessionData, error: sessionError } =
          await supabase.auth.getSession();

        if (sessionError) {
          console.error("Error getting session:", sessionError);
          setLoading(false);
          return;
        }

        const session = sessionData?.session;
        setUser(session?.user ?? null);

        // If we have a user but they might be new from OAuth, ensure they exist in the users table
        if (session?.user) {
          await ensureUserExists(session.user);
        }
      } catch (error) {
        console.error("Error initializing auth:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();

    // Listen for changes on auth state (signed in, signed out, etc.)
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log(`Auth state changed: ${event}`, session?.user?.id);
      // Log more detailed information for debugging
      if (session?.user) {
        console.log("User details:", {
          id: session.user.id,
          email: session.user.email,
          hasMetadata: !!session.user.user_metadata,
          authProvider: session.user.app_metadata?.provider || "email",
        });
      }

      // Handle different auth events
      switch (event) {
        case "SIGNED_IN":
          console.log("User signed in, ensuring user exists in DB");
          setUser(session?.user ?? null);
          if (session?.user) {
            await ensureUserExists(session.user);
          }
          break;

        case "SIGNED_OUT":
          console.log("User signed out");
          setUser(null);
          break;

        case "USER_UPDATED":
          console.log("User updated");
          setUser(session?.user ?? null);
          if (session?.user) {
            await ensureUserExists(session.user);
          }
          break;

        case "PASSWORD_RECOVERY":
          console.log("Password recovery initiated");
          break;

        case "TOKEN_REFRESHED":
          console.log("Token refreshed");
          setUser(session?.user ?? null);
          break;
      }

      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  // We're using the auth service functions directly
  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        signIn,
        signUp,
        signInWithGoogle,
        signOut,
        resetPassword,
        updatePassword,
        getUserProfile,
        updateUserProfile,
        deleteUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
