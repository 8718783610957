import React, { useState } from "react";
import DashboardLayout from "../dashboard/layout/DashboardLayout";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { ArrowLeft, ArrowRight, Plus, Save } from "lucide-react";
import AutomationBuilder from "../dashboard/AutomationBuilder";
import { useToast } from "../ui/use-toast";
import { useNavigate } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY,
);

const platforms = [
  { id: "finance", name: "Finance Dashboard" },
  { id: "salesforce", name: "Salesforce" },
  { id: "hubspot", name: "HubSpot" },
  { id: "excel", name: "Excel" },
  { id: "google_sheets", name: "Google Sheets" },
  { id: "linkedin", name: "LinkedIn" },
];

const CreateAutomation = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [automationData, setAutomationData] = useState({
    name: "",
    description: "",
    platform: "",
    scheduleType: "recurring",
    frequency: "daily",
    deliveryMethod: "email",
    steps: [],
    deliveryConfig: {
      email: "",
      subject: "",
      format: "html",
    },
  });
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  const handleChange = (field: string, value: string) => {
    setAutomationData({
      ...automationData,
      [field]: value,
    });
  };

  const updateDeliveryConfig = (field: string, value: string) => {
    setAutomationData({
      ...automationData,
      deliveryConfig: {
        ...automationData.deliveryConfig,
        [field]: value,
      },
    });
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const saveAutomation = async () => {
    try {
      setLoading(true);

      const { data, error } = await supabase.functions.invoke(
        "supabase-functions-create-automation",
        {
          body: automationData,
        },
      );

      if (error) throw error;

      toast({
        title: "Success",
        description: "Automation created successfully",
      });

      navigate("/dashboard/automations");
    } catch (error) {
      console.error("Error creating automation:", error);
      toast({
        title: "Error",
        description: "Failed to create automation. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const updateSteps = (steps: any[]) => {
    setAutomationData({
      ...automationData,
      steps,
    });
  };

  return (
    <DashboardLayout title="" description="">
      <div className="w-full">
        <div className="flex items-center mb-6">
          <Button
            variant="ghost"
            size="icon"
            className="mr-2"
            onClick={() => window.history.back()}
          >
            <ArrowLeft className="h-5 w-5" />
          </Button>
          <div>
            <h1 className="text-2xl font-bold text-gray-800">
              Create New Automation
            </h1>
            <p className="text-gray-600">
              Set up an automated workflow in a few steps
            </p>
          </div>
        </div>

        <div className="mb-8">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center space-x-2">
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  currentStep >= 1
                    ? "bg-primary text-white"
                    : "bg-gray-200 text-gray-500"
                }`}
              >
                1
              </div>
              <div className="text-sm font-medium">Basic Info</div>
            </div>
            <div className="h-0.5 w-12 bg-gray-200 flex-shrink-0" />
            <div className="flex items-center space-x-2">
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  currentStep >= 2
                    ? "bg-primary text-white"
                    : "bg-gray-200 text-gray-500"
                }`}
              >
                2
              </div>
              <div className="text-sm font-medium">Configure Steps</div>
            </div>
            <div className="h-0.5 w-12 bg-gray-200 flex-shrink-0" />
            <div className="flex items-center space-x-2">
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  currentStep >= 3
                    ? "bg-primary text-white"
                    : "bg-gray-200 text-gray-500"
                }`}
              >
                3
              </div>
              <div className="text-sm font-medium">Schedule</div>
            </div>
            <div className="h-0.5 w-12 bg-gray-200 flex-shrink-0" />
            <div className="flex items-center space-x-2">
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  currentStep >= 4
                    ? "bg-primary text-white"
                    : "bg-gray-200 text-gray-500"
                }`}
              >
                4
              </div>
              <div className="text-sm font-medium">Delivery</div>
            </div>
          </div>
        </div>

        {currentStep === 1 && (
          <Card>
            <CardHeader>
              <CardTitle>Basic Information</CardTitle>
              <CardDescription>
                Provide basic details about your automation
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="name">Automation Name</Label>
                <Input
                  id="name"
                  placeholder="e.g., Monthly Financial Report"
                  value={automationData.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="description">Description</Label>
                <Textarea
                  id="description"
                  placeholder="Describe what this automation does"
                  value={automationData.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="platform">Target Platform</Label>
                <Select
                  value={automationData.platform}
                  onValueChange={(value) => handleChange("platform", value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a platform" />
                  </SelectTrigger>
                  <SelectContent>
                    {platforms.map((platform) => (
                      <SelectItem key={platform.id} value={platform.id}>
                        {platform.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </CardContent>
            <CardFooter className="flex justify-end">
              <Button onClick={nextStep}>
                Next <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </CardFooter>
          </Card>
        )}

        {currentStep === 2 && (
          <AutomationBuilder
            initialSteps={automationData.steps}
            onStepsChange={updateSteps}
            platform={automationData.platform}
            onNext={nextStep}
            onBack={prevStep}
          />
        )}

        {currentStep === 3 && (
          <Card>
            <CardHeader>
              <CardTitle>Schedule Automation</CardTitle>
              <CardDescription>
                Set when and how often this automation should run
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Tabs
                defaultValue="recurring"
                value={automationData.scheduleType}
                onValueChange={(value) => handleChange("scheduleType", value)}
              >
                <TabsList className="mb-4">
                  <TabsTrigger value="recurring">Recurring</TabsTrigger>
                  <TabsTrigger value="one-time">One-time</TabsTrigger>
                  <TabsTrigger value="manual">Manual Trigger</TabsTrigger>
                </TabsList>

                <TabsContent value="recurring" className="space-y-4">
                  <div className="space-y-2">
                    <Label>Frequency</Label>
                    <Select
                      value={automationData.frequency}
                      onValueChange={(value) =>
                        handleChange("frequency", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="daily">Daily</SelectItem>
                        <SelectItem value="weekly">Weekly</SelectItem>
                        <SelectItem value="monthly">Monthly</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  {automationData.frequency === "weekly" && (
                    <div className="space-y-2">
                      <Label>Day of Week</Label>
                      <Select defaultValue="monday">
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="monday">Monday</SelectItem>
                          <SelectItem value="tuesday">Tuesday</SelectItem>
                          <SelectItem value="wednesday">Wednesday</SelectItem>
                          <SelectItem value="thursday">Thursday</SelectItem>
                          <SelectItem value="friday">Friday</SelectItem>
                          <SelectItem value="saturday">Saturday</SelectItem>
                          <SelectItem value="sunday">Sunday</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                  )}

                  {automationData.frequency === "monthly" && (
                    <div className="space-y-2">
                      <Label>Day of Month</Label>
                      <Select defaultValue="1">
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {Array.from({ length: 31 }, (_, i) => i + 1).map(
                            (day) => (
                              <SelectItem key={day} value={day.toString()}>
                                {day}
                              </SelectItem>
                            ),
                          )}
                        </SelectContent>
                      </Select>
                    </div>
                  )}

                  <div className="space-y-2">
                    <Label>Time</Label>
                    <Select defaultValue="9:00">
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                          <SelectItem key={hour} value={`${hour}:00`}>
                            {hour.toString().padStart(2, "0")}:00
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </TabsContent>

                <TabsContent value="one-time" className="space-y-4">
                  <div className="space-y-2">
                    <Label>Date</Label>
                    <Input type="date" />
                  </div>
                  <div className="space-y-2">
                    <Label>Time</Label>
                    <Input type="time" />
                  </div>
                </TabsContent>

                <TabsContent value="manual">
                  <div className="p-4 bg-gray-50 rounded-md text-center">
                    <p className="text-gray-600">
                      This automation will only run when manually triggered.
                    </p>
                  </div>
                </TabsContent>
              </Tabs>
            </CardContent>
            <CardFooter className="flex justify-between">
              <Button variant="outline" onClick={prevStep}>
                <ArrowLeft className="mr-2 h-4 w-4" /> Back
              </Button>
              <Button onClick={nextStep}>
                Next <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </CardFooter>
          </Card>
        )}

        {currentStep === 4 && (
          <Card>
            <CardHeader>
              <CardTitle>Delivery Options</CardTitle>
              <CardDescription>
                Configure how the automation results should be delivered
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Tabs
                defaultValue="email"
                value={automationData.deliveryMethod}
                onValueChange={(value) => handleChange("deliveryMethod", value)}
              >
                <TabsList className="mb-4">
                  <TabsTrigger value="email">Email</TabsTrigger>
                  <TabsTrigger value="slack">Slack</TabsTrigger>
                  <TabsTrigger value="drive">Cloud Storage</TabsTrigger>
                </TabsList>

                <TabsContent value="email" className="space-y-4">
                  <div className="space-y-2">
                    <Label>Recipient Email</Label>
                    <Input
                      type="email"
                      placeholder="email@example.com"
                      value={automationData.deliveryConfig.email || ""}
                      onChange={(e) =>
                        updateDeliveryConfig("email", e.target.value)
                      }
                    />
                  </div>
                  <div className="space-y-2">
                    <Label>Subject Line</Label>
                    <Input
                      placeholder="Automation Results: [Automation Name]"
                      value={automationData.deliveryConfig.subject || ""}
                      onChange={(e) =>
                        updateDeliveryConfig("subject", e.target.value)
                      }
                    />
                  </div>
                  <div className="space-y-2">
                    <Label>Email Format</Label>
                    <Select
                      defaultValue="html"
                      value={automationData.deliveryConfig.format || "html"}
                      onValueChange={(value) =>
                        updateDeliveryConfig("format", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="html">HTML</SelectItem>
                        <SelectItem value="text">Plain Text</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </TabsContent>

                <TabsContent value="slack" className="space-y-4">
                  <div className="space-y-2">
                    <Label>Slack Channel</Label>
                    <Input
                      placeholder="#channel-name"
                      value={automationData.deliveryConfig.channel || ""}
                      onChange={(e) =>
                        updateDeliveryConfig("channel", e.target.value)
                      }
                    />
                  </div>
                  <div className="space-y-2">
                    <Label>Message Format</Label>
                    <Select
                      defaultValue="detailed"
                      value={
                        automationData.deliveryConfig.messageFormat ||
                        "detailed"
                      }
                      onValueChange={(value) =>
                        updateDeliveryConfig("messageFormat", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="detailed">Detailed</SelectItem>
                        <SelectItem value="summary">Summary</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </TabsContent>

                <TabsContent value="drive" className="space-y-4">
                  <div className="space-y-2">
                    <Label>Storage Service</Label>
                    <Select
                      defaultValue="gdrive"
                      value={
                        automationData.deliveryConfig.storageService || "gdrive"
                      }
                      onValueChange={(value) =>
                        updateDeliveryConfig("storageService", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="gdrive">Google Drive</SelectItem>
                        <SelectItem value="dropbox">Dropbox</SelectItem>
                        <SelectItem value="onedrive">OneDrive</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label>Folder Path</Label>
                    <Input
                      placeholder="/Automations/Reports"
                      value={automationData.deliveryConfig.folderPath || ""}
                      onChange={(e) =>
                        updateDeliveryConfig("folderPath", e.target.value)
                      }
                    />
                  </div>
                  <div className="space-y-2">
                    <Label>File Naming</Label>
                    <Input
                      placeholder="[Automation Name] - [Date]"
                      value={automationData.deliveryConfig.fileNaming || ""}
                      onChange={(e) =>
                        updateDeliveryConfig("fileNaming", e.target.value)
                      }
                    />
                  </div>
                </TabsContent>
              </Tabs>
            </CardContent>
            <CardFooter className="flex justify-between">
              <Button variant="outline" onClick={prevStep}>
                <ArrowLeft className="mr-2 h-4 w-4" /> Back
              </Button>
              <Button onClick={saveAutomation} disabled={loading}>
                <Save className="mr-2 h-4 w-4" />{" "}
                {loading ? "Saving..." : "Save Automation"}
              </Button>
            </CardFooter>
          </Card>
        )}
      </div>
    </DashboardLayout>
  );
};

export default CreateAutomation;
